import React, { useState } from "react";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import { PATH_HOME } from "../../../../utils/paths";
import { ShopErrorModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  ModalButtonType,
  ModalCategoryType,
  ModalScreens,
  MODAL_ALERT,
} from "redmond";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface IAvailabilityErrorModalProps
  extends ShopErrorModalConnectorProps,
    RouteComponentProps {}

export const ShopErrorModal = (props: IAvailabilityErrorModalProps) => {
  const {
    hasFailed,
    history,
    fetchPremierCollectionShop,
    missingRoomProductInfo,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const modalEventProperties = {
    type: "pc_shop_error",
    primary_button: textConstants.SEARCH_AGAIN_BUTTON,
    secondary_button: textConstants.GO_BACK_BUTTON,
    screen: ModalScreens.HOTELS_SHOP,
    category: ModalCategoryType.TROUBLE,
  };
  React.useEffect(() => {
    if (hasFailed) {
      setIsOpen(true);
      trackEvent({
        eventName: MODAL_ALERT,
        properties: { ...modalEventProperties },
      });
    }
  }, [hasFailed]);

  const buttons = [
    {
      buttonText: textConstants.GO_BACK_BUTTON,
      buttonWrapperClassName: clsx(
        "availability-error-modal-button",
        "secondary"
      ),
      onClick: () => {
        setIsOpen(false);
        trackEvent({
          eventName: MODAL_ALERT,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.SECONDARY,
          },
        });
        history.push(PATH_HOME);
      },
    },
    {
      buttonText: textConstants.SEARCH_AGAIN_BUTTON,
      buttonWrapperClassName: clsx(
        "availability-error-modal-button",
        "primary"
      ),
      onClick: () => {
        setIsOpen(false);
        trackEvent({
          eventName: MODAL_ALERT,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.SECONDARY,
          },
        });
        fetchPremierCollectionShop(history, {
          fetchLodgingToken: cacheHotelTokenEnabled,
        });
      },
    },
  ];

  return (
    <GenericInfoPopup
      className="hotel-shop-error-modal-root"
      open={isOpen}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      title={
        missingRoomProductInfo
          ? textConstants.SHOP_MISSING_ROOMS_ERROR
          : textConstants.SHOP_ERROR_TITLE
      }
      subtitle={missingRoomProductInfo ? "" : textConstants.SHOP_ERROR_SUBTITLE}
      buttons={buttons}
      isMobile={matchesMobile}
    />
  );
};
