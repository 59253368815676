const getKeyFromValue = <E extends Record<string, string | number>>(
  enm: E,
  value: string | number,
  isCaseSensitive = false
): E[keyof E] | undefined =>
  Object.values(enm).find((val) =>
    isCaseSensitive || typeof val === "number" || typeof value === "number"
      ? val === value
      : val.toLowerCase() === value.toLowerCase()
  ) as E[keyof E] | undefined;

/**
 * Utility function to cast a string to an enum with a default value.
 * The key string can be a key or value of the enum.
 *
 * There's two overloads for this function, one with a default value and one without.
 * The version without a default value returns the enum value or undefined.
 *
 * @param enm The enum to cast to
 * @param key Key or Value to find in the enum
 * @param defaultValue Default value to return if the key is not found
 * @returns The enum value found, otherwise the default value if provided, otherwise undefined
 */
export const parseEnum: {
  <E extends Record<string, string | number>>(
    enm: E,
    key: string | number | undefined,
    defaultValue: E[keyof E]
  ): E[keyof E];
  <E extends Record<string, string | number>>(
    enm: E,
    key: string | number | undefined
  ): E[keyof E] | undefined;
} = <E extends Record<string, string | number>>(
  enm: E,
  key: string | number | undefined,
  defaultValue?: E[keyof E]
): E[keyof E] | undefined => {
  if (key == null) {
    return defaultValue;
  }
  const lookupByKey = enm[key] as E[keyof E] | undefined;
  if (lookupByKey != null) {
    return lookupByKey;
  }
  const keyFromValue = getKeyFromValue(enm, key);
  if (keyFromValue != null) {
    return keyFromValue;
  }
  return defaultValue;
};
