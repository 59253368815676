import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Chip, Divider, Typography } from "@material-ui/core";
import {
  CallState,
  CorpSessionInfo,
  ListingCollectionEnum,
  LodgingCollectionEnum,
  RewardsAccount,
  TokenizeCardErrors,
  TravelProductEnum,
} from "redmond";
import {
  B2BPaymentMethodSelectWorkflow,
  B2BSpinner,
  BannerSeverity,
  CheckoutPaymentForm,
  createElementId,
  GenericInfoPopup,
  getHSPEarnOfferDescription,
  getPaymentCardSubtitle,
  Icon,
  IconName,
  LoadingIndicator,
  LoadingPopup,
  NotificationBanner,
  PoweredByHopper,
  TEST_CARD_LAST_FOURS,
  useDeviceTypes,
  usePrevious,
} from "halifax";
import clsx from "clsx";
import { useExperimentIsVariant } from "@capone/experiments";
import { RouteComponentProps } from "react-router";
import { isCaponeTenant, isCorpTenant } from "@capone/common";

import "./styles.scss";
import { RewardsSelection } from "../RewardsSelection";
import { PaymentCardConnectorProps } from "./container";
import {
  ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
  ADD_PAYMENT_AGAIN,
  ADD_PAYMENT_FORM_HEADER_TEXT,
  ADD_PAYMENT_FORM_SUBTITLE_TEXT,
  ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS,
  ADD_PAYMENT_METHOD_MODAL_TITLE,
  BACK_TO_CARD_SELECTION_CTA,
  CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE,
  CAP_ONE_INVALID_CREDIT_CARD_TITLE,
  CARD_ENDING_IN_TEXT,
  CORP_MOBILE_PAYMENT_STEP_SUBTITLE_SHORTENED,
  CORP_MOBILE_PAYMENT_STEP_TITLE_SHORTENED,
  CORP_PAYMENT_STEP_2_TITLE,
  CORP_PAYMENT_STEP_SUBTITLE,
  CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT,
  CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT,
  EDIT_PAYMENT_METHOD,
  INELIGIBLE_ACCOUNTS_NOTICE,
  INELIGIBLE_ACCOUNTS_TOOLTIP,
  MOBILE_PAYMENT_STEP_SUBTITLE,
  MOBILE_PAYMENT_STEP_TITLE,
  PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS,
  PAYMENT_METHOD_TITLE,
  PAYMENT_STEP_2_TITLE,
  PAYMENT_STEP_SUBTITLE,
  PC_PAYMENT_TYPES_ELIGIBILITY_BANNER_TEXT,
  REWARDS_ACCOUNT_SUBTITLE,
  REWARDS_ACCOUNT_TITLE,
  TRY_AGAIN,
  UNABLED_TO_ADD_PAYMENT,
} from "./textConstants";
import { config } from "../../../../api/config";
import {
  ALL_CARDS_IN_PHC,
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CONTROL,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  useExperiments,
  VCN_ENABLEMENT,
} from "../../../../context/experiments";
import { TravelWalletSelection } from "../TravelWalletSelection";
import { ClientContext } from "../../../../App";

export interface IPaymentCardProps
  extends PaymentCardConnectorProps,
    RouteComponentProps {
  disabled?: boolean;
  className?: string;
}

export const shouldDisableCard =
  (canUseAllCards: boolean, isLifestyleCollection?: boolean) =>
  (rewardsAccount: RewardsAccount) => {
    if (canUseAllCards) {
      return false;
    }
    const allowedProducts = isCaponeTenant(config.TENANT)
      ? [
          ...(isLifestyleCollection ? ["Venture", "Spark Miles"] : []),
          "Venture X",
          "Spark Travel Elite",
          "Venture X Business",
        ]
      : ["Spark Miles", "Spark Miles Select"];
    const isInDisplayName = (product: string) =>
      isCaponeTenant(config.TENANT)
        ? rewardsAccount.productDisplayName.includes(product) &&
          rewardsAccount.productDisplayName !== "VentureOne" &&
          !rewardsAccount.productDisplayName.includes("Select")
        : rewardsAccount.productDisplayName.includes(product);
    const isCardEnabled = allowedProducts.some(isInDisplayName);
    return !isCardEnabled;
  };

const PaymentAddingElement = () => (
  <LoadingPopup
    indicatorSize="small"
    indicator={B2BSpinner}
    open
    popupSize="small"
    message="Adding your payment method"
    footer={PoweredByHopper}
  />
);

export const PaymentCard = ({
  listPaymentMethods,
  verifyPaymentMethod,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
  fetchAllEarnForProduct,
  selectedRewardsPaymentAccount,
  selectedRewardsPaymentAccountId,
  isCreditCardPaymentRequired,
  totalCreditCardPaymentRequired,
  paymentMethods,
  rewardsAccounts,
  selectedPaymentMethodId,
  listPaymentMethodCallState,
  verifyPaymentMethodCallState,
  deletePaymentMethodCallState,
  disabled = false,
  hasError,
  className,
  isTravelCreditPaymentOnly,
  isStackedTravelWalletPaymentOnly,
  isTravelWalletOfferPaymentOnly,
  selectedLodging,
  selectedHome,
  canRedeemRewards,
  offerToApply,
  earnValuesByRewardAcctId,
}: IPaymentCardProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { sessionInfo } = useContext(ClientContext);
  const [openErrorPaymentModal, setOpenErrorPaymentModal] = useState(false);
  const [isNotCapOneAccount, setIsNotCapOneAccount] = useState(false);
  const [tokenizeErrors, setTokenizeErrors] = useState<TokenizeCardErrors[]>(
    []
  );

  const corpSessionInfo = sessionInfo && (sessionInfo as CorpSessionInfo);

  useEffect(() => {
    listPaymentMethods();
    fetchRewardsAccounts(true);
  }, []);

  const previousOfferToApply = usePrevious(offerToApply);

  useEffect(() => {
    const offer = offerToApply || previousOfferToApply;

    const hspEarnDescription = getHSPEarnOfferDescription(offer);

    // re-fetch product to earn when toggling HSP earn offer to get correct earn
    if (hspEarnDescription) {
      fetchAllEarnForProduct();
    }
  }, [offerToApply]);

  const ineligibleRewardsAccounts = useMemo(
    () =>
      rewardsAccounts.filter(
        (account) => !(account.allowRewardsRedemption ?? true)
      ),
    [rewardsAccounts]
  );

  const handleOnAddPaymentMethod = (token: string, last4: string) => {
    // note: cap1 specific logic:
    // A card should be deemed ineligible if the last four digits do not match one of the cards associated with their accounts.
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isAddingVCNPaymentMethod = !!(
      matchingRewardsAccount?.lastFourVirtualCardNumbers &&
      matchingRewardsAccount?.lastFourVirtualCardNumbers?.includes(last4)
    );

    // TODO: bad practice, remove this in favor of real test accounts when we have them
    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || isTestCard || isCorpTenant(config.TENANT)) {
      verifyPaymentMethod(
        { token },
        matchingRewardsAccount?.accountReferenceId!,
        isAddingVCNPaymentMethod
      );
    } else {
      setIsNotCapOneAccount(true);
      setOpenErrorPaymentModal(true);
    }
  };

  const handleCloseErrorPopup = () => {
    isNotCapOneAccount && setIsNotCapOneAccount(false);
    setOpenErrorPaymentModal(false);
  };

  const renderCheckoutPaymentForm = () => (
    <CheckoutPaymentForm
      loading={verifyPaymentMethodCallState === CallState.InProcess}
      loadingEl={<PaymentAddingElement />}
      onSubmit={(token, last4) => {
        handleOnAddPaymentMethod(token, last4);
      }}
      saveLabel="Save"
      onError={(errors) => {
        setTokenizeErrors(errors);
        setOpenErrorPaymentModal(true);
      }}
      spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
      isMobile={matchesMobile}
      className="b2b"
    />
  );

  const expState = useExperiments();
  const canUseAllCardsExperiment = getExperimentVariant(
    expState.experiments,
    ALL_CARDS_IN_PHC
  );

  const canUseAllCards = useMemo(
    () => canUseAllCardsExperiment === AVAILABLE,
    [canUseAllCardsExperiment]
  );

  const vcnEnablement = getExperimentVariant(
    expState.experiments,
    VCN_ENABLEMENT
  );
  const isVCNEnabled = useMemo(
    () => vcnEnablement === AVAILABLE,
    [vcnEnablement]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(
    () => creditAndOfferStackingExperimentV1 === AVAILABLE,
    [creditAndOfferStackingExperimentV1]
  );

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const allowAnyCards = useExperimentIsVariant(
    "c1-corporate-credit-card-validation",
    "any-spark"
  );

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const rewardsSelectedNeeded =
    canRedeemRewards || isCaponeTenant(config.TENANT)
      ? selectedRewardsPaymentAccountId === undefined
      : false;

  const isPaymentMethodSelectDisabled =
    selectedRewardsPaymentAccount?.isTiered ||
    !isCreditCardPaymentRequired ||
    !totalCreditCardPaymentRequired ||
    rewardsSelectedNeeded;

  const eligiblePaymentTypesBannerText =
    PC_PAYMENT_TYPES_ELIGIBILITY_BANNER_TEXT(
      rewardsAccounts,
      allowAnyCards,
      paymentMethods,
      selectedLodging?.lodgingCollection === LodgingCollectionEnum.Lifestyle ||
        selectedHome?.listing.listingCollection ===
          ListingCollectionEnum.Lifestyle,
      isLCForNonPremiumCardHoldersEnabled
    );

  const isNonFinancialUser = corpSessionInfo?.corporateInfo?.cap1Role === "NFU";

  const paymentMethodWorkflowTitles = () => ({
    addPaymentCta:
      rewardsAccounts.length === 1 ? (
        <Box
          className={clsx(
            "add-payment-cta-container",
            {
              mobile: matchesMobile,
              disabled: disabled || isPaymentMethodSelectDisabled,
            },
            className
          )}
        >
          <Typography variant="body1">
            {CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT}
            <b className="card-name">{rewardsAccounts[0].productDisplayName}</b>
            {CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT}
          </Typography>
          {rewardsAccounts[0].earn.hotelsMultiplier &&
          rewardsAccounts[0].earn.hotelsMultiplier > 0 ? (
            <Chip
              label={`Earn ${rewardsAccounts[0].earn.hotelsMultiplier}X on hotels`}
              className={clsx("earn-chip", {
                enabled: !isPaymentMethodSelectDisabled,
              })}
            />
          ) : null}
        </Box>
      ) : (
        ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS
      ),
    addPaymentModalTitle: ADD_PAYMENT_METHOD_MODAL_TITLE,
    addAdditionalPaymentCta: ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
    backToCardSelectionCta: BACK_TO_CARD_SELECTION_CTA,
    paymentFormHeader: (cardName: string) => (
      <Typography
        variant="h4"
        dangerouslySetInnerHTML={{
          __html: ADD_PAYMENT_FORM_HEADER_TEXT(cardName),
        }}
      />
    ),
    paymentFormSubtitle: isVCNEnabled
      ? ADD_PAYMENT_FORM_SUBTITLE_TEXT
      : undefined,
    cardEndingIn: CARD_ENDING_IN_TEXT,
    addPaymentBannerText: !canUseAllCards
      ? eligiblePaymentTypesBannerText
      : undefined,
  });

  const getParadiseCardMessage = () =>
    rewardsAccounts.find((acct) =>
      acct.productDisplayName.toLowerCase().includes("paradise")
    ) ? (
      <NotificationBanner
        className={clsx("paradise-banner-notification", {
          mobile: matchesMobile,
        })}
        label="Bookings made with your Paradise card through Capital One Travel cannot be serviced by concierge services. If you’d like concierge to manage your booking, book in the Capital One concierge app."
        severity={BannerSeverity.NOTICE}
      />
    ) : null;

  const rewardsTitleId = createElementId("rewardsTitle");
  const rewardsSubtitleId = createElementId("rewardsSubtitle");

  return (
    <>
      {listPaymentMethodCallState === CallState.InProcess ||
      deletePaymentMethodCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize="small"
          indicator={B2BSpinner}
          message={
            listPaymentMethodCallState === CallState.InProcess
              ? `Fetching`
              : `Deleting`
          }
        />
      ) : (
        <Box
          className={clsx("payment-methods-container", {
            mobile: matchesMobile,
            disabled,
          })}
        >
          <Typography className="step-title" variant="h2">
            {matchesMobile
              ? isCaponeTenant(config.TENANT)
                ? MOBILE_PAYMENT_STEP_TITLE
                : CORP_MOBILE_PAYMENT_STEP_TITLE_SHORTENED(canRedeemRewards)
              : isCaponeTenant(config.TENANT)
              ? PAYMENT_STEP_2_TITLE
              : CORP_PAYMENT_STEP_2_TITLE(canRedeemRewards)}
          </Typography>
          <Typography variant="body2" className="payment-step-subtitle">
            {isCreditAndOfferStackingExperimentV1
              ? PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS(
                  !isCorpHideTravelOffers
                )
              : matchesMobile
              ? isCaponeTenant(config.TENANT)
                ? MOBILE_PAYMENT_STEP_SUBTITLE
                : CORP_MOBILE_PAYMENT_STEP_SUBTITLE_SHORTENED(canRedeemRewards)
              : isCaponeTenant(config.TENANT)
              ? PAYMENT_STEP_SUBTITLE
              : CORP_PAYMENT_STEP_SUBTITLE(canRedeemRewards)}
          </Typography>
          {canRedeemRewards && (
            <>
              {ineligibleRewardsAccounts.length > 0 && (
                <NotificationBanner
                  className="authorized-users-rewards-banner"
                  label={INELIGIBLE_ACCOUNTS_NOTICE(rewardsAccounts)}
                  severity={BannerSeverity.NOTICE}
                  tooltip={
                    rewardsAccounts.length === ineligibleRewardsAccounts.length
                      ? {
                          label: INELIGIBLE_ACCOUNTS_TOOLTIP(
                            ineligibleRewardsAccounts
                          ),
                          icon: IconName.InfoCircle,
                        }
                      : undefined
                  }
                />
              )}
              {!canUseAllCards && eligiblePaymentTypesBannerText && (
                <NotificationBanner
                  className="pc-eligible-cards-banner"
                  content={eligiblePaymentTypesBannerText}
                  severity={BannerSeverity.NOTICE}
                />
              )}
              <Divider className="payment-methods-container-divider" />
              {isCreditAndOfferStackingExperimentV1 && (
                <TravelWalletSelection
                  disabled={disabled}
                  isMobile={matchesMobile}
                  isAnnualTravelCreditsExperiment={
                    isAnnualTravelCreditsExperiment
                  }
                  isCreditAndOfferStackingExperimentV1={
                    isCreditAndOfferStackingExperimentV1
                  }
                  isTravelSale={isTravelSaleEnabled}
                />
              )}{" "}
              {getParadiseCardMessage()}
              <Typography variant="h3" className="rewards-account-title">
                {REWARDS_ACCOUNT_TITLE(config.TENANT)}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: REWARDS_ACCOUNT_SUBTITLE(config.TENANT),
                }}
                className="rewards-accounts-subtitle"
              />
              <RewardsSelection
                {...{
                  disabled:
                    disabled ||
                    (isCreditAndOfferStackingExperimentV1 &&
                      (isTravelCreditPaymentOnly ||
                        isStackedTravelWalletPaymentOnly ||
                        isTravelWalletOfferPaymentOnly)),
                  rewardsTitleId,
                  rewardsSubtitleId,
                }}
              />
            </>
          )}
          <Divider className="payment-methods-container-divider" />
          <Typography className="payment-method-title" variant="h3">
            {PAYMENT_METHOD_TITLE}
          </Typography>
          {getPaymentCardSubtitle(
            rewardsAccounts[0],
            corpSessionInfo?.corporateInfo?.businessName ?? "",
            isNonFinancialUser,
            config.TENANT,
            totalCreditCardPaymentRequired,
            !selectedPaymentMethodId,
            isPaymentMethodSelectDisabled
          )}
          <B2BPaymentMethodSelectWorkflow
            errorModalOpen={openErrorPaymentModal}
            rewardsAccounts={rewardsAccounts ?? []}
            savedPayments={paymentMethods ?? []}
            selectedPaymentHopperId={selectedPaymentMethodId}
            disabled={
              isPaymentMethodSelectDisabled ||
              disabled ||
              hasError ||
              (isCreditAndOfferStackingExperimentV1 &&
                (isTravelCreditPaymentOnly ||
                  isStackedTravelWalletPaymentOnly ||
                  isTravelWalletOfferPaymentOnly))
            }
            selectPaymentMethod={(paymentId, rewardsAccount) => {
              setSelectedPaymentMethodId({
                paymentMethodId: paymentId,
                accountId: rewardsAccount?.accountReferenceId,
              });
              fetchProductToEarn();
            }}
            removePaymentMethod={(paymentId: string) => {
              deletePaymentMethod({ paymentId });
            }}
            titles={paymentMethodWorkflowTitles()}
            renderCheckoutPaymentForm={renderCheckoutPaymentForm}
            isMobile={matchesMobile}
            product={
              selectedHome
                ? TravelProductEnum.Homes
                : selectedLodging?.lodgingCollection ===
                  LodgingCollectionEnum.Lifestyle
                ? TravelProductEnum.LifestyleCollection
                : TravelProductEnum.PremierCollection
            }
            loading={verifyPaymentMethodCallState === CallState.InProcess}
            buttonClassName="b2b"
            fullScreenWithBanner={matchesMobile}
            paymentMethodDisabled={shouldDisableCard(
              canUseAllCards,
              selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle ||
                selectedHome?.listing.listingCollection ===
                  ListingCollectionEnum.Lifestyle
            )}
            isVCNEnabled={isVCNEnabled}
            tenant={config.TENANT}
            showEarnValue
            earnValuesByRewardAcctId={earnValuesByRewardAcctId}
            corporateInfo={corpSessionInfo?.corporateInfo}
          />
        </Box>
      )}
      <GenericInfoPopup
        open={openErrorPaymentModal}
        image={
          <Icon
            className="error-icon"
            name={
              isNotCapOneAccount
                ? IconName.ErrorState
                : IconName.UnableToProcess
            }
          />
        }
        title={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_TITLE
            : UNABLED_TO_ADD_PAYMENT
        }
        subtitle={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE
            : tokenizeErrors.length > 0
            ? tokenizeErrors[0].message
            : ADD_PAYMENT_AGAIN
        }
        buttons={[
          {
            buttonText: EDIT_PAYMENT_METHOD,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-secondary",
          },
          {
            buttonText: TRY_AGAIN,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-primary",
            buttonWrapperClassName: "b2b",
          },
        ]}
        isMobile={matchesMobile}
      />
    </>
  );
};
