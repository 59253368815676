import React, { useState } from "react";
import { Icon, IconName } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { DesktopPremierCollectionShopDetailsChooseDatesConnectorProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import "./styles.scss";
import clsx from "clsx";
import { CustomerAccountRole } from "redmond";
import { CalendarPickerButton } from "../../../search/components/PremierCollectionSearchControl/components/CalendarPickerButton";
import { OccupancySelection } from "../../../search/components/PremierCollectionSearchControl/components/OccupancySelection";
import { HotelSearchButton } from "../../../search/components/PremierCollectionSearchControl/components/SearchButton";
import { transformToStringifiedQuery } from "../../../shop/utils/queryStringHelpers";
import * as textConstants from "./textConstants";

export interface DesktopPremierCollectionShopDetailsChooseDatesProps
  extends DesktopPremierCollectionShopDetailsChooseDatesConnectorProps,
    RouteComponentProps {
  variant?: "default" | "lifestyle-collection";
  handleShopCall: (queryParams: string) => void;
  isUnavailable?: boolean;
}

export const DesktopPremierCollectionShopDetailsChooseDates = (
  props: DesktopPremierCollectionShopDetailsChooseDatesProps
) => {
  const {
    variant = "default",
    handleShopCall,
    isUnavailable,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    lodgingData,
    largestEarnAccount,
  } = props;
  const [datesSelected, setDatesSelected] = useState(false);

  const searchShop = () => {
    const params = transformToStringifiedQuery({
      lodgingId: lodgingData?.id || "",
      fromDate: fromDate,
      untilDate: untilDate,
      selectedLodgingIndex: 0, // defaulting to 0 since not from availability list
      adultsCount: adultsCount,
      childrenCount: childrenCount,
      selectedAccountIndex: 0,
    });
    handleShopCall(params);
  };
  const isReadyToSearch = !!fromDate && !!untilDate && !!adultsCount;
  const handleSearchClick = () => {
    isReadyToSearch && datesSelected ? searchShop() : null;
  };

  const onSearchDates = () => {
    setDatesSelected(true);
  };

  return (
    <Box
      className={clsx(
        "pc-shop-details-choose-dates-button-and-benefits-wrapper",
        {
          "lifestyle-collection": variant === "lifestyle-collection",
        }
      )}
    >
      {variant === "lifestyle-collection" ? (
        <Icon name={IconName.LifestyleCollectionRibbon} />
      ) : (
        <Icon name={IconName.PremierCollectionRibbon} />
      )}
      <Box className="hotel-shop-details-choose-dates-container">
        <Box className="hotel-shop-details-choose-dates-content">
          <Box className="hotel-shop-details-room-pill">
            <Typography className="hotel-shop-details-room-pill-text">
              {isUnavailable
                ? textConstants.SELECT_DATES_ERROR
                : textConstants.SELECT_DATES}
            </Typography>
          </Box>
          <Box className={"date-input"}>
            <CalendarPickerButton
              classes={[
                clsx("room-calendar-button", {
                  "search-disabled":
                    !isReadyToSearch || (isUnavailable && !datesSelected),
                }),
              ]}
              onSearchDates={onSearchDates}
              hideSeparator={true}
            />
          </Box>
          <Box className={"occupancy-selection dateless-search"}>
            <OccupancySelection />
          </Box>
          <HotelSearchButton
            className={clsx("shop-details-search-control-button", "b2b")}
            message={textConstants.SEARCH}
            onClick={() => {
              handleSearchClick();
            }}
            enabled={isReadyToSearch}
          />
        </Box>
      </Box>
      {largestEarnAccount && largestEarnAccount.earn.hotelsMultiplier && (
        <Box className="pc-shop-benefits">
          <Box className="pc-benefits-title">
            <Icon name={IconName.StarOutline} />
            <Typography className="pc-benefits-title-text">
              {variant === "lifestyle-collection"
                ? textConstants.LC_BENEFITS_TITLE
                : textConstants.BENEFITS_TITLE}
            </Typography>
          </Box>
          <Typography className="pc-benefits-list">
            {textConstants.getBenefitsList(
              largestEarnAccount.earn.hotelsMultiplier,
              variant,
              largestEarnAccount.customerAccountRole ===
                CustomerAccountRole.Primary
            )}
          </Typography>
          <Box className="additional-benefits">
            <Icon name={IconName.GiftOutline} />
            <Typography className="additional-benefits-text">
              {variant === "lifestyle-collection"
                ? textConstants.LC_ADDITIONAL_BENEFITS
                : textConstants.ADDITIONAL_BENEFITS}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
