import React, { useState, useEffect, useContext } from "react";
import { Box } from "@material-ui/core";
import {
  ContactInfoWorkflow,
  IContactInfo,
  TravelerSelectStep,
  ContactInfoStep,
  Header,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  LoadingPopup,
  B2BSpinner,
  emailRegex,
  phoneRegex,
  Icon,
  IconName,
  TreesConfirmation,
  InformationalModal as TreesModal,
  TravelWalletSingleSelectionStep,
  AccordionCollection,
  usePrevious,
} from "halifax";
import { ADD_CONTACT_INFO, IPerson, ListingCollectionEnum } from "redmond";
import { RouteComponentProps } from "react-router-dom";

import "./styles.scss";
import { MobileVacationRentalsBookWorkflowConnectorProps } from "./container";
import {
  PremierCollectionBookPassengerSelection,
  PaymentCard,
  TitleSummaryCard,
  PremierCollectionBookMobileButton,
  VacationRentalsPriceBreakdownDropdown,
  AGENT_FEE,
} from "..";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_SUBTITLE,
  REVIEW_MY_TRIP_TEXT,
  PRICE_QUOTE_MESSAGE,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  EXPERIENCE_CREDIT_FAQ,
} from "./textConstants";
import {
  PATH_HOME,
  PATH_VACATION_RENTALS_BOOK_CONFIRMATION,
} from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import clsx from "clsx";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { goToShop } from "../../../shop/utils/queryStringHelpers";
import { fetchCustomerDetails } from "../../../../api/v0/customer/fetchCustomerDetails";
import { TravelOfferSelection } from "../TravelOfferSelection";
import queryStringParser from "query-string";
import PremierCollectionCheckoutBenefits from "../PremierCollectionCheckoutBenefits";
import { SelectedPrimaryTravelerInfo } from "../SelectedPrimaryTravelerInfo";
import { SelectedVacationRentalSummary } from "../SelectedVacationRentalSummary";
import { ListingTermsInfo } from "../../../vacation-rental-shop/components/ListingTermsInfo";
import { VacationRentalPriceBreakdown } from "../VacationRentalPriceBreakdownCard";

export interface IMobileVacationRentalsBookWorkflowProps
  extends MobileVacationRentalsBookWorkflowConnectorProps,
    RouteComponentProps {
  isTreesModalExperiment: boolean;
  isTravelWalletOfferExperiment: boolean;
  isTravelWalletCreditsExperiment: boolean;
  isCreditAndOfferStackingExperimentV1: boolean;
  isTravelCreditHistoryExperiment: boolean;
  primaryTraveler?: IPerson;
}

enum MobileVacationRentalsBookWorkflowStep {
  PrimaryTravelerSelected,
  TravelerInformation,
  ContactInformation,
  TravelOfferSelection,
  RewardsAndPayment,
  Review,
}

export const MobileVacationRentalsBookWorkflow = ({
  priceQuote,
  hasNoUserPassengers,
  scheduleVacationRentalPriceQuote,
  history,
  vrReservation,
  isBookingValid,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDifferenceAcknowledged,
  scheduleVacationRentalsBook,
  priceQuoteInProgress,
  priceQuoteErrors,
  confirmationDetailsErrors,
  offers,
  fetchApplicableTravelWalletItems,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  isTravelWalletPaymentOnly,
  creditToApply,
  credit,
  fetchTravelWalletCreditHistory,
  largestEarnAccount,
  isTreesModalExperiment,
  isTravelWalletOfferExperiment,
  isTravelWalletCreditsExperiment,
  isCreditAndOfferStackingExperimentV1,
  isTravelCreditHistoryExperiment,
  primaryTraveler,
  setUserSelectedPassengerIds,
  selectedHome,
  userSelectedTraveler,
  addMainGuest,
}: IMobileVacationRentalsBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;
  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);
  const [checkoutStep, setCheckoutStep] =
    useState<MobileVacationRentalsBookWorkflowStep>(0);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [travelOfferSelectStep, setTravelOfferSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [updatedInfoOnReview, setUpdatedInfoOnReview] = useState(false);

  const [openPrimaryTravelerInfoModal, setOpenPrimaryTravelerInfoModal] =
    useState<boolean>(false);
  const [travelerToEdit, setTravelerToEdit] = useState<IPerson | undefined>(
    undefined
  );

  const prevCustomerContactLoading = usePrevious(customerDetailsLoading);
  const prevConfirmationEmail = usePrevious(confirmationEmail);
  const onBookPremierCollection = () => {
    const queryString = queryStringParser.parse(history.location.search);
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleVacationRentalsBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
        })
      : scheduleVacationRentalPriceQuote({
          history,
          emailAddress: confirmationEmail ?? "",
          agentFee: isAgentPortal ? AGENT_FEE : 0,
        });
  };

  const openPremierCollectionBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openTravelOffersSelection = () => {
    setTravelOfferSelectStep(
      TravelWalletSingleSelectionStep.TravelWalletSelection
    );
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  useEffect(() => {
    if (priceQuote && confirmationEmail) {
      addMainGuest({
        emailAddress: confirmationEmail,
        mainGuest: userSelectedTraveler
          ? { ...userSelectedTraveler, phoneNumber: confirmationPhoneNumber }
          : null,
      });
    }
  }, [priceQuote]);

  const incrementCheckoutStep = () => {
    setCheckoutStep((step) => {
      if (
        step === MobileVacationRentalsBookWorkflowStep.PrimaryTravelerSelected
      ) {
        if (
          (isTravelWalletCreditsExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !credit
            : true) &&
          (isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !offers?.length
            : true)
        ) {
          return step + 4;
        } else {
          return step + 3;
        }
      }
      if (
        (step === MobileVacationRentalsBookWorkflowStep.ContactInformation &&
          isCreditAndOfferStackingExperimentV1) ||
        (step === MobileVacationRentalsBookWorkflowStep.ContactInformation &&
          (isTravelWalletCreditsExperiment ? !credit : true) &&
          (isTravelWalletOfferExperiment ? !offers?.length : true)) ||
        (step === MobileVacationRentalsBookWorkflowStep.TravelOfferSelection &&
          isTravelWalletPaymentOnly)
      ) {
        return step + 2;
      }
      return step === MobileVacationRentalsBookWorkflowStep.Review
        ? step
        : step + 1;
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case MobileVacationRentalsBookWorkflowStep.PrimaryTravelerSelected:
      case MobileVacationRentalsBookWorkflowStep.TravelerInformation:
        if (selectedHome) {
          goToShop({ history });
        } else {
          history.push(PATH_HOME);
        }
        break;
      case MobileVacationRentalsBookWorkflowStep.RewardsAndPayment:
        if (
          (isTravelWalletCreditsExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !credit
            : true) &&
          (isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !offers?.length
            : true)
        ) {
          if (primaryTraveler) {
            setCheckoutStep((step) => step - 4);
          } else {
            setCheckoutStep((step) => step - 2);
          }
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      case MobileVacationRentalsBookWorkflowStep.Review:
        if (isTravelWalletPaymentOnly) {
          setCheckoutStep((step) => step - 2);
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      default:
        setCheckoutStep((step) => step - 1);
        break;
    }
  };
  const closeAllPopovers = () => {
    setTravelerWorkflowStep(TravelerSelectStep.Main);
    setContactInfoStep(ContactInfoStep.Main);
    setTravelOfferSelectStep(TravelWalletSingleSelectionStep.Main);
    setOpenPaymentCard(false);
    setOpenPrimaryTravelerInfoModal(false);
  };

  const startSchedulePriceQuoteAndResetPayment = () => {
    scheduleVacationRentalPriceQuote({
      history,
      emailAddress: confirmationEmail ?? "",
      agentFee: isAgentPortal ? AGENT_FEE : 0,
    });
    resetPaymentCardSelectedAccounts();
    setUpdatedInfoOnReview(true);
  };

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setCheckoutStep(
        MobileVacationRentalsBookWorkflowStep.TravelerInformation
      );
    }
  }, [priceQuoteErrors.length, confirmationDetailsErrors.length]);

  useEffect(() => {
    switch (checkoutStep) {
      case MobileVacationRentalsBookWorkflowStep.PrimaryTravelerSelected:
        closeAllPopovers();
        setOpenPrimaryTravelerInfoModal(true);
        break;
      case MobileVacationRentalsBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openPremierCollectionBookPassengerSelection();
        break;
      case MobileVacationRentalsBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openPremierCollectionBookPassengerSelection();
        break;
      case MobileVacationRentalsBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case MobileVacationRentalsBookWorkflowStep.TravelOfferSelection:
        closeAllPopovers();
        openTravelOffersSelection();
        break;
      case MobileVacationRentalsBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep ===
        MobileVacationRentalsBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  useEffect(() => {
    if (vrReservation) {
      history.push(PATH_VACATION_RENTALS_BOOK_CONFIRMATION);
    }
  }, [vrReservation]);

  React.useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(
          contactInfo?.email || "",
          `${
            details?.phoneNumber?.includes("+")
              ? ""
              : contactInfo?.countryCode ?? "+1"
          }${details?.phoneNumber}` || "" // We default to "+1" in `PhoneInputField` if there isn't a countrycode saved
        );
        setCustomerDetailsLoading(true);
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems(true);
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, []);

  useEffect(() => {
    // check to see if the the previous confirmation email was empty for user's without an email in their profile
    if (
      !customerDetailsLoading &&
      confirmationEmail &&
      (prevCustomerContactLoading !== customerDetailsLoading || prevConfirmationEmail === "")
    ) {
      // Note: Run PQ only automatically upon landing on page (after we get customer info) or else this runs everytime email input field is updated) {
      scheduleVacationRentalPriceQuote({
        history,
        emailAddress: confirmationEmail,
        agentFee: isAgentPortal ? AGENT_FEE : 0,
        pollQuoteOnly: true,
      });
    }
  }, [customerDetailsLoading, confirmationEmail, prevCustomerContactLoading]);

  useEffect(() => {
    if (
      checkoutStep === MobileVacationRentalsBookWorkflowStep.Review &&
      !priceQuoteInProgress &&
      !!priceQuote &&
      updatedInfoOnReview
    ) {
      if (creditToApply) {
        setCheckoutStep(
          MobileVacationRentalsBookWorkflowStep.TravelOfferSelection
        ); // if user has selected a travel wallet item before PQ is run again on pax/contact info update, bring them back to that step
      } else {
        handleGoBack();
      }
    }
  }, [checkoutStep, priceQuoteInProgress, priceQuote, updatedInfoOnReview]);

  useEffect(() => {
    if (priceQuoteInProgress && !!priceQuote) {
      setPriceQuote(null, null);
    }
  }, [priceQuoteInProgress]);

  useEffect(() => {
    if (
      !customerDetailsLoading &&
      sessionInfo &&
      (checkoutStep ===
        MobileVacationRentalsBookWorkflowStep.TravelerInformation || // Note: added this check because it goes to traveler step endlessly
        checkoutStep ===
          MobileVacationRentalsBookWorkflowStep.PrimaryTravelerSelected)
    ) {
      if (
        !primaryTraveler ||
        !contactInfo?.email ||
        !contactInfo?.phoneNumber
      ) {
        setCheckoutStep(
          MobileVacationRentalsBookWorkflowStep.TravelerInformation
        );
      } else {
        setCheckoutStep(
          MobileVacationRentalsBookWorkflowStep.PrimaryTravelerSelected
        );
        setOpenPrimaryTravelerInfoModal(true);
        setUserSelectedPassengerIds(
          setUserSelectedPassengerIds({
            userSelectedPassengerIds: [primaryTraveler.id],
          })
        );
      }
    }
  }, [
    primaryTraveler,
    customerDetailsLoading,
    contactInfo,
    sessionInfo,
    checkoutStep,
  ]);

  if (!selectedHome) {
    return null;
  }

  return (
    <>
      <Box
        className={clsx(
          "mobile-vacation-rentals-book-workflow-root",
          "display-room-details-modal",
          {
            "mobile-review-premier-collection-book":
              checkoutStep === MobileVacationRentalsBookWorkflowStep.Review,
            "lifestyle-collection":
              selectedHome.listing.listingCollection ===
              ListingCollectionEnum.Lifestyle,
            "premier-collection":
              selectedHome.listing.listingCollection ===
              ListingCollectionEnum.Premier,
          }
        )}
      >
        {/* TODO: Header should have pricing details */}
        <Header
          className={clsx("mobile-premier-collection-book-header", "checkout")}
          left={
            <BackButton
              className="mobile-premier-collection-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={
            <VacationRentalsPriceBreakdownDropdown
              popoverClassName={
                checkoutStep === MobileVacationRentalsBookWorkflowStep.Review
                  ? "mobile-review-premier-collection-book-price-breakdown"
                  : undefined
              }
            />
          }
          isMobile={true}
          fullWidth={true}
        />
        <TitleSummaryCard isMobile />
        <SelectedVacationRentalSummary isMobile />
        {primaryTraveler && confirmationPhoneNumber && confirmationEmail && (
          <SelectedPrimaryTravelerInfo
            primaryTraveler={primaryTraveler}
            phoneNumber={confirmationPhoneNumber}
            email={confirmationEmail}
            header="Traveler information"
            onEditTraveler={() => {
              setTravelerToEdit(primaryTraveler);
              setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
            }}
            openPrimaryTravelerInfoModal={openPrimaryTravelerInfoModal}
            isMobile
            mobileHeaderElement={<VacationRentalsPriceBreakdownDropdown />}
            onContinueClick={() => {
              incrementCheckoutStep();
            }}
            onGoBack={handleGoBack}
          />
        )}
        <PremierCollectionBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileVacationRentalsBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case MobileVacationRentalsBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue;
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileVacationRentalsBookWorkflowStep.Review:
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={
            <VacationRentalsPriceBreakdownDropdown />
          }
          className="b2b"
          onReviewStep={
            checkoutStep === MobileVacationRentalsBookWorkflowStep.Review
          }
          isMobile={true}
          useLocalId={true}
          travelerToEdit={travelerToEdit}
          setTravelerToEdit={setTravelerToEdit}
          preselectedUserId={
            primaryTraveler && contactInfo?.email && contactInfo?.phoneNumber
              ? primaryTraveler.id
              : undefined
          }
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE,
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === MobileVacationRentalsBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          isMobile={true}
          contactInfo={contactInfo}
          onGoBack={() => {
            if (
              checkoutStep ===
              MobileVacationRentalsBookWorkflowStep.ContactInformation
            ) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(contactInfo) => {
            setContact(contactInfo);
            setContactInfo(contactInfo.email, contactInfo.phoneNumber);
            if (
              contactInfo.email &&
              contactInfo.phoneNumber &&
              emailRegex.test(contactInfo.email) &&
              phoneRegex.test(contactInfo.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
            incrementCheckoutStep();
          }}
          mobileHeaderElement={<VacationRentalsPriceBreakdownDropdown />}
          className="b2b"
          onContinueClick={() => {
            switch (checkoutStep) {
              case MobileVacationRentalsBookWorkflowStep.Review:
                startSchedulePriceQuoteAndResetPayment();
                break;
              default:
                // For user's without an email in their profile the schedule call will error
                // out that's why we need to check if the email is not empty
                !primaryTraveler && confirmationEmail !== ""
                  ? scheduleVacationRentalPriceQuote({
                      history,
                      emailAddress: confirmationEmail ?? "",
                      agentFee: isAgentPortal ? AGENT_FEE : 0,
                    })
                  : undefined;
                break;
            }
          }}
          loading={customerDetailsLoading}
        />
        {largestEarnAccount && largestEarnAccount.earn.hotelsMultiplier && (
          <PremierCollectionCheckoutBenefits
            variant={
              selectedHome?.listing?.listingCollection ===
              ListingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "premier-collection"
            }
            largestEarnAccount={largestEarnAccount}
            isVR
          />
        )}
        <ListingTermsInfo selectedListing={selectedHome} isMobile />

        {isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelOfferSelectStep}
              mobileHeaderElement={<VacationRentalsPriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  MobileVacationRentalsBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelOfferSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <Box className="mobile-hotels-price-breakdown">
          <VacationRentalPriceBreakdown />
        </Box>

        <AccordionCollection
          accordionContents={EXPERIENCE_CREDIT_FAQ}
          expandIcon={<Icon name="plus" />}
          collapseIcon={<Icon name="minus" />}
        />

        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}

        <PremierCollectionBookMobileButton
          hasContactInfo={!!contactInfo}
          openContactInfo={() => {
            setContactInfoStep(ContactInfoStep.ContactInfoForm);
          }}
          isBookingValid={isBookingValid}
          onConfirmAndBook={() => onBookPremierCollection()}
          // TODO: simplify/trim PremierCollectionBookMobileButton logic
          showPaymentStep={true}
          onApplyRewards={() => {}}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (
            checkoutStep ===
            MobileVacationRentalsBookWorkflowStep.RewardsAndPayment
          ) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={<VacationRentalsPriceBreakdownDropdown />}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={priceQuoteInProgress}
        message={PRICE_QUOTE_MESSAGE}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen={true}
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("vacation-rentals-payment-card-popup", "b2b")}
      fullScreen={true}
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="vacation-rentals-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className="vacation-rentals-book-review-trip-button"
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {REVIEW_MY_TRIP_TEXT}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
