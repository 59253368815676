import axios from "axios";
import { config } from "../../config";

import { LodgingTokenRequest, LodgingTokenResponse } from "redmond";

import { cacheLodgingTokenApiPrefix } from "../paths";

export const fetchCacheLodgingToken = (
  body: LodgingTokenRequest
): Promise<LodgingTokenResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(cacheLodgingTokenApiPrefix, body, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
