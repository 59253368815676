import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { useDeviceTypes } from "halifax";

import { PremierCollectionShopDetailsConnectorProps } from "./container";
import { DesktopShopDetails } from "./components/DesktopShopDetails";
import {
  CallState,
  VIEWED_PC_DETAILS,
  LodgingCollectionEnum,
  VIEWED_LC_DETAILS,
  StayTypesEnum,
  VIEWED_DATELESS_HOTEL_DETAILS,
  VIEWED_UNAVAILABLE_DATELESS_HOTEL_DETAILS,
  combineProps,
} from "redmond";
import { PORTAL_TITLE, VIEW_HOTEL_TITLE } from "./textConstants";
import { handlePageRedirectOnSelectRoomProduct } from "../shop/utils/queryStringHelpers";
import {
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  LUXURY_DATELESS_SEARCH_EXPERIMENT,
  useExperiments,
} from "../../context/experiments";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import "./styles.scss";
import { PATH_HOME } from "../../utils/paths";
import { MobileShopDetails } from "./components/MobileShopDetails";
import { parseShopDetailsQueryString } from "./utils/queryStringHelpers";

export interface IHotelShopProps
  extends PremierCollectionShopDetailsConnectorProps,
    RouteComponentProps {}

export const PremierCollectionShopDetails = (props: IHotelShopProps) => {
  const {
    fetchPremierCollectionShopDetails,
    history,
    viewedPremierCollectionDetailsProperties,
    fetchTravelWalletDetails,
    fetchedPremierCollectionDetails,
    fetchTravelWalletCreditHistory,
    selectedLodgingData,
    setStayType,
    listPaymentMethods,
    setIsFromHotelDatelessSearch,
  } = props;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const [isReadyToRedirect, setIsReadyToRedirect] = useState<boolean>(false);

  const expState = useExperiments();

  const datelessSearchExperiment = getExperimentVariant(
    expState.experiments,
    LUXURY_DATELESS_SEARCH_EXPERIMENT
  );
  const isDatelessSearch = datelessSearchExperiment !== CONTROL;
  if (!isDatelessSearch) {
    history.replace(PATH_HOME);
    return null;
  }

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const queryString = useMemo(
    () => parseShopDetailsQueryString(history.location.search),
    [history.location.search]
  );

  const source = queryString.source;

  const handleReadyToRedirect = () => {
    // note: it needs to wait for `selectRoomType` to be executed before proceeding with the page redirect
    setTimeout(() => {
      setIsReadyToRedirect(true);
    });
  };

  useEffect(() => {
    if (selectedLodgingData) {
      document.title = selectedLodgingData.name;
    }
  }, [selectedLodgingData]);

  useEffect(() => {
    fetchTravelWalletCreditHistory();
    fetchTravelWalletDetails();
    listPaymentMethods();
    setStayType(StayTypesEnum.Hotels);
    document.title = VIEW_HOTEL_TITLE;
    setTimeout(() => window.scrollTo(0, 0), 0);

    setIsFromHotelDatelessSearch(true);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (fetchedPremierCollectionDetails) {
      trackEvent({
        eventName:
          selectedLodgingData?.lodgingCollection ===
          LodgingCollectionEnum.Lifestyle
            ? VIEWED_LC_DETAILS
            : VIEWED_PC_DETAILS,
        ...viewedPremierCollectionDetailsProperties,
      });

      trackEvent({
        eventName: VIEWED_DATELESS_HOTEL_DETAILS,
        ...combineProps(viewedPremierCollectionDetailsProperties, {
          properties: {
            source: source,
          },
          encryptedProperties: [],
        }),
      });
    }
  }, [fetchedPremierCollectionDetails]);

  useEffect(() => {
    if (queryString.unavailable && fetchedPremierCollectionDetails) {
      trackEvent({
        eventName: VIEWED_UNAVAILABLE_DATELESS_HOTEL_DETAILS,
        ...viewedPremierCollectionDetailsProperties,
      });
    }
  }, [queryString.unavailable, fetchedPremierCollectionDetails]);

  useEffect(() => {
    // Only load the data when needed, ie. when the experiments are loaded and the data is not already loaded or the lodging
    // id has changed.
    if (
      (CallState.Success === expState.callState ||
        expState.experiments.length > 0) &&
      (selectedLodgingData == null ||
        queryString.lodgingId != selectedLodgingData.id)
    ) {
      fetchPremierCollectionShopDetails(history, {
        overrideStateByQueryParams: true,
      });
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  }, [
    expState.callState,
    expState.experiments.length,
    queryString.lodgingId,
    selectedLodgingData,
  ]);

  /*
    note: selectRoomType (the action that updates indexes in redux state) is called within a halifax component as a callback;
    it's executed at the same time as when `onClickContinue` is handled, which means that some states are not yet updated.
  */
  useEffect(() => {
    if (isReadyToRedirect) {
      handlePageRedirectOnSelectRoomProduct({
        history,
      });
      setIsReadyToRedirect(false);
    }
  }, [history, isReadyToRedirect]);

  return (
    <>
      <Box className={"premier-collection-shop-root"}>
        {matchesDesktop && (
          <DesktopShopDetails
            handleReadyToRedirect={handleReadyToRedirect}
            variant={
              (isLCForNonPremiumCardHoldersEnabled ||
                isLCForPremiumCardHoldersEnabled) &&
              selectedLodgingData?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "default"
            }
            source={source}
          />
        )}
        {matchesMobile && (
          <MobileShopDetails
            handleReadyToRedirect={handleReadyToRedirect}
            variant={
              (isLCForNonPremiumCardHoldersEnabled ||
                isLCForPremiumCardHoldersEnabled) &&
              selectedLodgingData?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "default"
            }
            source={source}
          />
        )}
      </Box>
    </>
  );
};
