import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import { getPremierCollectionShopDetailsCallState } from "./reducer/selectors";
import { PremierCollectionShopDetails } from "./component";
import { actions } from "./actions";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../travel-wallet/actions/actions";
import {
  setIsFromHotelDatelessSearch,
  setStayType,
} from "../search/actions/actions";
import { listPaymentMethods } from "../availability/actions/actions";
import {
  getPremierCollectionShopDetailsSelectedLodgingData,
  getViewedPremierCollectionDetailsProperties,
  PremierCollectionShopDetailsCallState,
  getPremierCollectionShopDetailsRoomsInfo,
} from "./reducer";

const mapStateToProps = (state: IStoreState) => {
  const callState = getPremierCollectionShopDetailsCallState(state);
  return {
    selectedLodgingData:
      getPremierCollectionShopDetailsSelectedLodgingData(state),
    viewedPremierCollectionDetailsProperties:
      getViewedPremierCollectionDetailsProperties(state),
    fetchedPremierCollectionDetails:
      callState === PremierCollectionShopDetailsCallState.Failed ||
      callState === PremierCollectionShopDetailsCallState.Success,
    roomInfoProducts: getPremierCollectionShopDetailsRoomsInfo(state),
  };
};

export const mapDispatchToProps = {
  fetchPremierCollectionShopDetails: actions.fetchPremierCollectionShopDetails,
  selectRoomType: actions.selectRoomType,
  fetchTravelWalletDetails,
  fetchTravelWalletCreditHistory,
  setStayType,
  listPaymentMethods,
  setIsFromHotelDatelessSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PremierCollectionShopDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionShopDetails = withRouter(
  connector(PremierCollectionShopDetails)
);
