import * as H from "history";

import {
  AmenityEnum,
  APPLIED_HOTEL_FILTER,
  AppliedHotelFilterProperties,
  AvailabilityResponse,
  BoundingBox,
  GuestsSelection,
  HotelStarRatingEnum,
  IResult,
  LodgingCollectionEnum,
  MealPlanKindEnum,
  Payment,
  VacationRentalAmenityKindEnum,
  VacationRentalsAvailabilityResponse,
} from "redmond";

import {
  PremierCollectionAvailabilitySortOption,
  VacationRentalsRoomCounts,
} from "../reducer";
import * as actionTypes from "./constants";
import {trackEvent} from "../../../api/v0/analytics/trackEvent";

export interface IFetchInitialPremierCollectionAvailability {
  type: actionTypes.FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY;
  history: H.History;
  lodgingCollection: LodgingCollectionEnum;
  mapBounds?: Omit<BoundingBox, "LocationDescriptor">;
}

export interface IFetchMorePremierCollectionAvailability {
  type: actionTypes.FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY;
  history: H.History;
  lodgingCollection: LodgingCollectionEnum;
}

export type IFetchPremierCollectionAvailability =
  | IFetchInitialPremierCollectionAvailability
  | IFetchMorePremierCollectionAvailability;

export const fetchInitialPremierCollectionAvailability = (
  history: H.History,
  lodgingCollection: LodgingCollectionEnum,
  options?: { mapBounds: Omit<BoundingBox, "LocationDescriptor"> }
): IFetchInitialPremierCollectionAvailability => ({
  type: actionTypes.FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY,
  history,
  lodgingCollection,
  mapBounds: options?.mapBounds,
});

export const fetchMorePremierCollectionAvailability = (
  history: H.History,
  lodgingCollection: LodgingCollectionEnum
): IFetchPremierCollectionAvailability => ({
  type: actionTypes.FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY,
  history,
  lodgingCollection,
});

export interface ISetPremierCollectionAvailabilityResults {
  type: actionTypes.SET_PREMIER_COLLECTION_AVAILABILITY_RESULTS;
  payload: ISetPremierCollectionAvailabilityResultsPayload;
}

export type ISetPremierCollectionAvailabilityResultsPayload =
  AvailabilityResponse;

export const setPremierCollectionAvailabilityResults = (args: {
  payload: ISetPremierCollectionAvailabilityResultsPayload;
}): ISetPremierCollectionAvailabilityResults => ({
  type: actionTypes.SET_PREMIER_COLLECTION_AVAILABILITY_RESULTS,
  ...args,
});

export const setPremierCollectionAvailabilityCallStateFailed =
  (): ISetPremierCollectionAvailabilityCallStateFailed => ({
    type: actionTypes.SET_PREMIER_COLLECTION_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface ISetPremierCollectionAvailabilityCallStateFailed {
  type: actionTypes.SET_PREMIER_COLLECTION_AVAILABILITY_CALL_STATE_FAILED;
}

export interface ISetAmenitiesFilter {
  type: actionTypes.SET_AMENITIES_FILTER;
  amenities: AmenityEnum[];
}

export const setAmenitiesFilter = (
  amenities: AmenityEnum[]
): ISetAmenitiesFilter => ({
  type: actionTypes.SET_AMENITIES_FILTER,
  amenities,
});

export interface ISetStarRatingsFilter {
  type: actionTypes.SET_STAR_RATINGS_FILTER;
  starRatings: HotelStarRatingEnum[];
}

export const setStarRatingsFilter = (
  starRatings: HotelStarRatingEnum[]
): ISetStarRatingsFilter => ({
  type: actionTypes.SET_STAR_RATINGS_FILTER,
  starRatings,
});

export interface ISetMaxPriceFilter {
  type: actionTypes.SET_MAX_PRICE_FILTER;
  maxPrice: number;
}

export const setMaxPriceFilter = (maxPrice: number): ISetMaxPriceFilter => ({
  type: actionTypes.SET_MAX_PRICE_FILTER,
  maxPrice,
});

export interface ISetCancellationFilter {
  type: actionTypes.SET_PREMIER_COLLECTION_CANCELLATION_FILTER;
  freeCancellation: boolean;
}

export const setCancellationFilter = (
  freeCancellation: boolean
): ISetCancellationFilter => ({
  type: actionTypes.SET_PREMIER_COLLECTION_CANCELLATION_FILTER,
  freeCancellation,
});

export interface ISetHotelsOnSaleFilter {
  type: actionTypes.SET_PREMIER_COLLECTION_HOTELS_ON_SALE_FILTER;
  hotelsOnSale: boolean;
}

export const setHotelsOnSaleFilter = (
  hotelsOnSale: boolean
): ISetHotelsOnSaleFilter => ({
  type: actionTypes.SET_PREMIER_COLLECTION_HOTELS_ON_SALE_FILTER,
  hotelsOnSale,
});

export interface ISetPremierCollectionNameFilter {
  type: actionTypes.SET_PREMIER_COLLECTION_NAME_FILTER;
  hotelName: string;
}

export const setPremierCollectionNameFilter = (
  hotelName: string
): ISetPremierCollectionNameFilter => {
  return {
    type: actionTypes.SET_PREMIER_COLLECTION_NAME_FILTER,
    hotelName,
  };
};

export interface ISetPremierHotelsMealPlanTypesFilter {
  type: actionTypes.SET_PREMIER_HOTELS_MEAL_PLAN_TYPES_FILTER;
  mealPlan: MealPlanKindEnum[];
}

export const setPremierHotelsMealPlanTypeFilter = (
    mealPlan: MealPlanKindEnum[]
): ISetPremierHotelsMealPlanTypesFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "meal_plan_types",
  };
  if (mealPlan.length > 0) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_PREMIER_HOTELS_MEAL_PLAN_TYPES_FILTER,
    mealPlan: mealPlan,
  };
}

export interface ISetPremierCollectionAvailabilitySortOption {
  type: actionTypes.SET_PREMIER_COLLECTION_SORT_OPTION;
  sortOption: PremierCollectionAvailabilitySortOption;
}

export const setPremierCollectionAvailabilitySortOption = (
  sortOption: PremierCollectionAvailabilitySortOption
): ISetPremierCollectionAvailabilitySortOption => ({
  type: actionTypes.SET_PREMIER_COLLECTION_SORT_OPTION,
  sortOption,
});

export interface ISetMapSearchQuery {
  type: actionTypes.SET_MAP_SEARCH_QUERY;
  query: string;
}

export const setMapSearchQuery = (query: string): ISetMapSearchQuery => ({
  type: actionTypes.SET_MAP_SEARCH_QUERY,
  query,
});

export interface ISetMapBound {
  type: actionTypes.SET_MAP_BOUND;
  mapBound?: Omit<BoundingBox, "LocationDescriptor">;
}

export const setMapBound = (
  mapBound?: Omit<BoundingBox, "LocationDescriptor">
): ISetMapBound => ({
  type: actionTypes.SET_MAP_BOUND,
  mapBound,
});

export interface ISetPropertyIdInFocus {
  type: actionTypes.SET_PROPERTY_ID_IN_FOCUS;
  propertyId: string | null;
}

export const setPropertyIdInFocus = (
  propertyId: string | null
): ISetPropertyIdInFocus => ({
  type: actionTypes.SET_PROPERTY_ID_IN_FOCUS,
  propertyId,
});

export interface ISetPropertyIdHovered {
  type: actionTypes.SET_PROPERTY_ID_HOVERED;
  propertyId: string | null;
}

export const setPropertyIdHovered = (
  propertyId: string | null
): ISetPropertyIdHovered => ({
  type: actionTypes.SET_PROPERTY_ID_HOVERED,
  propertyId,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetSearchedDates {
  type: actionTypes.SET_SEARCHED_DATES;
  searchedFromDate: Date;
  searchedUntilDate: Date;
}

export const setSearchedDates = (
  searchedFromDate: Date,
  searchedUntilDate: Date
): ISetSearchedDates => ({
  type: actionTypes.SET_SEARCHED_DATES,
  searchedFromDate,
  searchedUntilDate,
});

export interface ISetSearchedLocationResult {
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT;
  searchedLocationResult: IResult | null;
}

export const setSearchedLocationResult = (
  searchedLocationResult: IResult | null
): ISetSearchedLocationResult => ({
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT,
  searchedLocationResult,
});

export interface ISetSelectedLodgingIndex {
  type: actionTypes.SET_SELECTED_LODGING_INDEX;
  index: number;
}

export const setSelectedLodgingIndex = (index: number) => ({
  type: actionTypes.SET_SELECTED_LODGING_INDEX,
  index,
});

export interface ISetSearchedOccupancyCounts {
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setSearchedOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetSearchedOccupancyCounts => ({
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS,
  counts,
});

export interface IFetchInitialVacationRentalsAvailability {
  type: actionTypes.FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY;
  history: H.History;
  includePremierCollection?: boolean;
  mapBounds?: Omit<BoundingBox, "LocationDescriptor">;
}

export interface IFetchMoreVacationRentalsAvailability {
  type: actionTypes.FETCH_MORE_VACATION_RENTALS_AVAILABILITY;
  history: H.History;
  includePremierCollection?: boolean;
}

export type IFetchVacationRentalsAvailability =
  | IFetchInitialVacationRentalsAvailability
  | IFetchMoreVacationRentalsAvailability;

export const fetchInitialVacationRentalsAvailability = (
  history: H.History,
  options?: {
    includePremierCollection?: boolean;
    mapBounds?: Omit<BoundingBox, "LocationDescriptor">;
  }
): IFetchVacationRentalsAvailability => ({
  type: actionTypes.FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY,
  history,
  ...(options ?? {}),
});

export const fetchMoreVacationRentalsAvailability = (
  history: H.History
): IFetchVacationRentalsAvailability => ({
  type: actionTypes.FETCH_MORE_VACATION_RENTALS_AVAILABILITY,
  history,
});

export interface ISetVacationRentalsAvailabilityResults {
  type: actionTypes.SET_VACATION_RENTALS_AVAILABILITY_RESULTS;
  payload: ISetVacationRentalsAvailabilityResultsPayload;
  isInitialRequest: boolean;
}

export type ISetVacationRentalsAvailabilityResultsPayload =
  VacationRentalsAvailabilityResponse;

export const setVacationRentalsAvailabilityResults = (args: {
  payload: ISetVacationRentalsAvailabilityResultsPayload;
  isInitialRequest: boolean;
}): ISetVacationRentalsAvailabilityResults => ({
  type: actionTypes.SET_VACATION_RENTALS_AVAILABILITY_RESULTS,
  ...args,
});

export const setVacationRentalsAvailabilityCallStateFailed =
  (): ISetVacationRentalsAvailabilityCallStateFailed => ({
    type: actionTypes.SET_VACATION_RENTALS_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface ISetVacationRentalsAvailabilityCallStateFailed {
  type: actionTypes.SET_VACATION_RENTALS_AVAILABILITY_CALL_STATE_FAILED;
}

export interface ISetVacationRentalsRoomCounts {
  type: actionTypes.SET_VACATION_RENTALS_ROOM_COUNTS;
  counts: VacationRentalsRoomCounts;
}

export const setVacationRentalsRoomCounts = (
  counts: VacationRentalsRoomCounts
): ISetVacationRentalsRoomCounts => ({
  type: actionTypes.SET_VACATION_RENTALS_ROOM_COUNTS,
  counts,
});

export interface ISetVacationRentalsAmenities {
  type: actionTypes.SET_VACATION_RENTALS_AMENITIES;
  amenities: VacationRentalAmenityKindEnum[];
}

export const setVacationRentalsAmenities = (
  amenities: VacationRentalAmenityKindEnum[]
): ISetVacationRentalsAmenities => ({
  type: actionTypes.SET_VACATION_RENTALS_AMENITIES,
  amenities,
});

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export type PremierCollectionAvailabilityActions =
  | IFetchPremierCollectionAvailability
  | ISetPremierCollectionAvailabilityResults
  | ISetPremierCollectionAvailabilityCallStateFailed
  | ISetAmenitiesFilter
  | ISetStarRatingsFilter
  | ISetMaxPriceFilter
  | ISetCancellationFilter
  | ISetHotelsOnSaleFilter
  | ISetPremierCollectionNameFilter
  | ISetPremierHotelsMealPlanTypesFilter
  | ISetPremierCollectionAvailabilitySortOption
  | ISetMapSearchQuery
  | ISetMapBound
  | ISetOpenDatesModal
  | ISetPropertyIdInFocus
  | ISetPropertyIdHovered
  | ISetSearchedDates
  | ISetSearchedLocationResult
  | ISetSelectedLodgingIndex
  | ISetSearchedOccupancyCounts
  | IFetchVacationRentalsAvailability
  | ISetVacationRentalsAvailabilityResults
  | ISetVacationRentalsAvailabilityCallStateFailed
  | ISetVacationRentalsRoomCounts
  | ISetVacationRentalsAmenities
  | IListPaymentMethods
  | ISetPaymentMethods
  | ISetPaymentMethodsCallStateFailed;
