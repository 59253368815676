import dayjs from "dayjs";
import { History } from "history";
import queryStringParser from "query-string";

import { ListingSearchResult } from "redmond";

import {
  PATH_HOME,
  PATH_VACATION_RENTALS_AVAILABILITY,
} from "../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../shop/utils/queryStringHelpers";

export interface VacationRentalShopQuery {
  listingId: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  petsCount: number;
  location: string;
}

export const transformToStringifiedVRQuery = ({
  listingId,
  fromDate,
  untilDate,
  adultsCount = 2,
  childrenCount = 0,
  petsCount = 0,
  location,
}: VacationRentalShopQuery): string => {
  if (fromDate === null || untilDate === null) {
    return `?listingId=${listingId}`;
  }

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  let string = `?listingId=${listingId}&locationName=${location}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}&childrenCount=${childrenCount}&petsCount=${petsCount}`;

  return string;
};

export interface IVacationRentalShopParsedQuery {
  listingId: string;
  fromDate: string;
  untilDate: string;
  adultsCount: number;
  childrenCount: number;
  petsCount: number;
  location: string;
  lodgingSelection?: string;
}

export const goToVRAvailability = ({
  history,
  listing,
  fromDate,
  untilDate,
  adultsCount,
  childrenCount,
  petsCount,
}: {
  history: History;
  listing?: ListingSearchResult | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  petsCount?: number;
}) => {
  if (!listing) {
    return history.push(PATH_HOME);
  }

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  const location = decodeURIComponent(
    parsedQueryStringPrimitive.locationName as string
  );
  const search = transformToStringifiedAvailabilityQuery({
    location: encodeURIComponent(location),
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
  });

  history.push(`${PATH_VACATION_RENTALS_AVAILABILITY}${search}`);
};
