import React from "react";

import { Typography } from "@material-ui/core";
import { isEqual } from "lodash-es";
import { RouteComponentProps } from "react-router";

import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  FreeCancelFilter,
  HotelNameSelection,
  HotelPriceSelection,
  Icon,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
  StarRatingsSelection,
  getCurrencySymbol,
  mapAmenitiesToTopAmenities,
  mapTopAmenitiesToAmenities,
  topAmenities,
  MealPlanTypeFilter,
} from "halifax";
import {
  AmenityEnum,
  HotelStarRatingEnum,
  MealPlanKindEnum,
  StayTypesEnum,
  VacationRentalAmenityKindEnum,
} from "redmond";

import {
  PremierCollectionAvailabilitySortOption,
  VacationRentalsRoomCountOptionValue,
  VacationRentalsRoomCounts,
  initialState,
} from "../../../../reducer";
import { PRICE_FILTER_SUBTITLE, PRICE_FILTER_TITLE } from "../../textConstants";
import { AmenitiesSelection } from "../AmenitiesSelection/component";
import { AMENITY_LABELS } from "../AmenitiesSelection/textConstants";
import { RoomCountSelection } from "../RoomCountSelection";
import { SortSelection } from "../SortSelection";
import { AllFiltersModalConnectorProps } from "./container";
import {
  APPLY_FILTERS_BUTTON_TEXT,
  APPLY_FILTERS_COUNT_BUTTON_TEXT,
  FILTERS_MODAL_CTA_TEXT,
  FILTERS_MODAL_HEADING_TEXT,
  RESET_FILTERS_BUTTON_TEXT,
  MEAL_PLAN_FILTER_TITLE,
} from "./textConstants";

import "./styles.scss";

export interface IAllFiltersModalProps
  extends AllFiltersModalConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showMealPlanTypeFilter?: boolean;
}

export const AllFiltersModal = ({
  stayType,
  currency,
  open,
  setOpen,
  isMobile = false,
  showMealPlanTypeFilter = false,
  sortOption,
  setSortOption,
  roomCounts,
  setRoomCounts,
  hotelMaxPriceFilter,
  setMaxPriceFilter,
  hotelMinMaxPriceRange,
  freeCancellationFilter,
  setCancellationFilter,
  hotelsOnSaleFilter,
  setHotelsOnSaleFilter,
  starRatings,
  setStarRatingsFilter,
  vrAmenities,
  setVRAmenities,
  hotelAmenities,
  setHotelAmenities,
  hotelNameFilter,
  setPremierCollectionNameFilter,
  hotelsFiltersCount,
  vrFiltersCount,
  hotelsMealPlanFilter,
  setPremierHotelsMealPlanTypeFilter,
}: IAllFiltersModalProps) => {
  // modal state
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const isVR = stayType === StayTypesEnum.VacationRentals;
  const outerFiltersCount = React.useMemo(() => {
    switch (stayType) {
      case StayTypesEnum.Hotels:
        return hotelsFiltersCount;
      case StayTypesEnum.VacationRentals:
        return vrFiltersCount;
    }
  }, [hotelsFiltersCount, vrFiltersCount]);

  // local filter state
  const [localSortOption, setLocalSortOption] =
    React.useState<PremierCollectionAvailabilitySortOption>(sortOption);
  const [localCounts, setLocalCounts] =
    React.useState<VacationRentalsRoomCounts>(roomCounts);
  const [localVRAmenities, setLocalVRAmenities] =
    React.useState<VacationRentalAmenityKindEnum[]>(vrAmenities);
  const [localHotelAmenities, setLocalHotelAmenities] =
    React.useState<AmenityEnum[]>(hotelAmenities);
  const [localMaxPriceFilter, setLocalMaxPriceFilter] =
    React.useState<number>(hotelMaxPriceFilter);
  const [localStarRatings, setLocalStarRatings] = React.useState(starRatings);
  const [localFreeCancellation, setLocalFreeCancellation] = React.useState(
    freeCancellationFilter
  );
  const [localHotelsOnSale, setLocalHotelsOnSale] =
    React.useState(hotelsOnSaleFilter);
  const [localHotelName, setLocalHotelName] = React.useState(hotelNameFilter);
  const [localHotelsMealPlan, setLocalHotelsMealPlan] =
    React.useState<MealPlanKindEnum[]>(hotelsMealPlanFilter);
  // modal handlers
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleReset = () => {
    setLocalSortOption(initialState.sortOption);
    setLocalCounts(initialState.vacationRentalsRoomCounts);
    setLocalVRAmenities(initialState.vacationRentalsAmenities);
    setLocalHotelAmenities(initialState.amenities);
    setLocalMaxPriceFilter(initialState.maxPrice);
    setLocalStarRatings(initialState.starRatings);
    setLocalFreeCancellation(initialState.freeCancellation);
    setLocalHotelName(initialState.hotelName);
    setLocalHotelsOnSale(initialState.hotelsOnSale);
    setLocalHotelsMealPlan(initialState.mealPlan);
  };
  const handleApply = () => {
    setSortOption(localSortOption);
    setRoomCounts(localCounts);
    setVRAmenities(localVRAmenities);
    setHotelAmenities(localHotelAmenities);
    setMaxPriceFilter(localMaxPriceFilter);
    setStarRatingsFilter(localStarRatings);
    setCancellationFilter(localFreeCancellation);
    setPremierCollectionNameFilter(localHotelName);
    setHotelsOnSaleFilter(localHotelsOnSale);
    setPremierHotelsMealPlanTypeFilter(localHotelsMealPlan);

    handleModalClose();
  };
  const resetLocalToOuterState = () => {
    setLocalSortOption(sortOption);
    setLocalCounts(roomCounts);
    setLocalVRAmenities(vrAmenities);
    setLocalHotelAmenities(hotelAmenities);
    setLocalMaxPriceFilter(hotelMaxPriceFilter);
    setLocalStarRatings(starRatings);
    setLocalFreeCancellation(freeCancellationFilter);
    setLocalHotelName(hotelNameFilter);
    setLocalHotelsOnSale(hotelsOnSaleFilter);
    setLocalHotelsMealPlan(localHotelsMealPlan);
  };

  const filtersToApplyCount = React.useMemo(
    () =>
      [
        localSortOption !== sortOption,
        localFreeCancellation !== freeCancellationFilter,
        localHotelName !== hotelNameFilter,
        localHotelsOnSale !== hotelsOnSaleFilter,
        !isEqual(localHotelAmenities, hotelAmenities),
        !isEqual(localVRAmenities, vrAmenities),
        !isEqual(localStarRatings, starRatings),
        !isEqual(localMaxPriceFilter, hotelMaxPriceFilter),
        !isEqual(localHotelsMealPlan, hotelsMealPlanFilter),
        localCounts[VacationRentalsRoomCountOptionValue.MIN_BATHROOMS] !==
          roomCounts[VacationRentalsRoomCountOptionValue.MIN_BATHROOMS],
        localCounts[VacationRentalsRoomCountOptionValue.MIN_BEDROOMS] !==
          roomCounts[VacationRentalsRoomCountOptionValue.MIN_BEDROOMS],
        localCounts[VacationRentalsRoomCountOptionValue.MIN_BEDS] !==
          roomCounts[VacationRentalsRoomCountOptionValue.MIN_BEDS],
        localCounts[VacationRentalsRoomCountOptionValue.MIN_GUESTS] !==
          roomCounts[VacationRentalsRoomCountOptionValue.MIN_GUESTS],
      ].filter((isSet) => isSet).length,
    [
      localSortOption,
      localVRAmenities,
      localHotelAmenities,
      localCounts,
      localMaxPriceFilter,
      localStarRatings,
      localFreeCancellation,
      localHotelName,
      localHotelsOnSale,
      localHotelsMealPlan,
    ]
  );

  const hasValuesToApply = filtersToApplyCount > 0;

  const canReset = React.useMemo(
    () =>
      [
        localSortOption !== initialState.sortOption,
        localFreeCancellation !== initialState.freeCancellation,
        localHotelName !== initialState.hotelName,
        localHotelsOnSale !== initialState.hotelsOnSale,
        !isEqual(localHotelAmenities, initialState.amenities),
        !isEqual(localVRAmenities, initialState.vacationRentalsAmenities),
        !isEqual(localStarRatings, initialState.starRatings),
        !isEqual(localMaxPriceFilter, initialState.maxPrice),
        !isEqual(localHotelsMealPlan, initialState.mealPlan),
          localCounts[VacationRentalsRoomCountOptionValue.MIN_BATHROOMS] !==
          initialState.vacationRentalsRoomCounts[
            VacationRentalsRoomCountOptionValue.MIN_BATHROOMS
          ],
        localCounts[VacationRentalsRoomCountOptionValue.MIN_BEDROOMS] !==
          initialState.vacationRentalsRoomCounts[
            VacationRentalsRoomCountOptionValue.MIN_BEDROOMS
          ],
        localCounts[VacationRentalsRoomCountOptionValue.MIN_BEDS] !==
          initialState.vacationRentalsRoomCounts[
            VacationRentalsRoomCountOptionValue.MIN_BEDS
          ],
        localCounts[VacationRentalsRoomCountOptionValue.MIN_GUESTS] !==
          initialState.vacationRentalsRoomCounts[
            VacationRentalsRoomCountOptionValue.MIN_GUESTS
          ],
      ].some((isSet) => isSet),
    [
      localSortOption,
      localVRAmenities,
      localHotelAmenities,
      localCounts,
      localMaxPriceFilter,
      localStarRatings,
      localFreeCancellation,
      localHotelName,
      localHotelsOnSale,
      localHotelsMealPlan,
    ]
  );

  const hotelTopAmenities = React.useMemo(
    () => mapAmenitiesToTopAmenities(localHotelAmenities),
    [localHotelAmenities]
  );

  React.useEffect(() => {
    resetLocalToOuterState();
  }, [modalOpen]);

  React.useEffect(() => {
    if (open !== undefined) setModalOpen(open);
  }, [open]);

  React.useEffect(() => {
    setOpen?.(modalOpen);
  }, [modalOpen]);

  const labelPlacement: "start" | "end" = React.useMemo(
    () => (isMobile ? "start" : "end"),
    [isMobile]
  );

  function renderFilters() {
    return (
      <>
        {isMobile && (
          <>
            <SortSelection
              icon={IconName.Sort}
              selectedSortOption={localSortOption}
              setSortOption={setLocalSortOption}
              labelPlacement={labelPlacement}
            />
            <hr />
          </>
        )}
        {isVR ? (
          <>
            <RoomCountSelection
              counts={localCounts}
              setCounts={setLocalCounts}
              isMobile={isMobile}
            />
            <hr />
            <AmenitiesSelection
              title="Amenities"
              icon={IconName.KnifeFork}
              labelPlacement={labelPlacement}
              amenities={[VacationRentalAmenityKindEnum.PetFriendly]}
              getAmenityLabel={(amenity) => AMENITY_LABELS[amenity] ?? amenity}
              selectedAmenities={localVRAmenities}
              setSelectedAmenities={setLocalVRAmenities}
            />
          </>
        ) : (
          <>
            <StarRatingsSelection
              title={"Rating"}
              labelPlacement={labelPlacement}
              starRatingOptions={[
                HotelStarRatingEnum.Five,
                HotelStarRatingEnum.Four,
                HotelStarRatingEnum.Three,
              ]}
              starRatings={localStarRatings}
              setStarRatings={setLocalStarRatings}
              icon={IconName.StarOutline}
            />
            <hr />
            {showMealPlanTypeFilter && (
              <>
                <MealPlanTypeFilter
                  title={MEAL_PLAN_FILTER_TITLE}
                  mealPlanTypes={localHotelsMealPlan}
                  setMealPlanTypes={setLocalHotelsMealPlan}
                  icon={IconName.MealPlan}
                  isMobile={isMobile}
                  showMealPlanTypeFilter={showMealPlanTypeFilter}
                />
                <hr />
             </>
          )}
            <AmenitiesSelection
              title="Amenities"
              icon={IconName.KnifeFork}
              amenities={topAmenities}
              labelPlacement={labelPlacement}
              selectedAmenities={hotelTopAmenities}
              getAmenityLabel={(amenity) => amenity.label}
              amenitiesEqual={(a, b) => a.value === b.value}
              setSelectedAmenities={(amenities) =>
                setLocalHotelAmenities(
                  mapTopAmenitiesToAmenities(
                    amenities.map((amenity) => amenity.value)
                  )
                )
              }
            />
            <hr />
            <HotelPriceSelection
              title={PRICE_FILTER_TITLE}
              subtitle={PRICE_FILTER_SUBTITLE}
              icon={IconName.MoneyOutlineThin}
              className="desktop-premier-collection-availability-price"
              setHotelMaxPrice={setLocalMaxPriceFilter}
              hotelPriceRange={hotelMinMaxPriceRange ?? { min: 0, max: 0 }}
              hotelMaxPrice={localMaxPriceFilter}
              currencySymbol={getCurrencySymbol(currency)}
              type="max"
              alwaysShowTooltip
              showMinLabel
              showClearButton={localMaxPriceFilter !== initialState.maxPrice}
              reset={() => setLocalMaxPriceFilter(initialState.maxPrice)}
            />
            <hr />
            <FreeCancelFilter
              icon={IconName.NotAllowedSign}
              className="desktop-selection-content"
              title={"Free Cancellation"}
              hasFreeCancelFilter={localFreeCancellation}
              setFreeCancelFilter={setLocalFreeCancellation}
            />
            {/* <hr />
            {
              // Hiding the HotelsOnSaleFilter for now until it is fixed as per https://hopper-jira.atlassian.net/browse/CLUX-343
              <HotelsOnSaleFilter
                title={"Limited-Time Promotions"}
                icon={IconName.PreferredHotelFilter}
                hasHotelsOnSaleFilter={localHotelsOnSale}
                setHotelsOnSaleFilter={setLocalHotelsOnSale}
              />
            } */}
            <hr />
            <HotelNameSelection
              className="desktop-selection-content"
              title={"Hotel Name"}
              subTitle={
                "Looking for a specific hotel? Enter the name of the hotel you’d like to stay at in this area."
              }
              placeholder={"What hotel are you looking for?"}
              hotelName={localHotelName}
              setHotelName={setLocalHotelName}
              icon={IconName.HotelFunnelIcon}
            />
          </>
        )}
      </>
    );
  }

  if (isMobile) {
    return (
      <>
        <MobilePopoverCard
          open={modalOpen}
          fullScreen
          onClose={handleModalClose}
          // We want to render an empty header so that we can set its height in CSS,
          // to render the hairline below the header correctly.
          headerElement={<></>}
          topLeftButton={
            canReset ? (
              <ActionLink
                className="mobile-vacation-rentals-filters-reset-button"
                content={RESET_FILTERS_BUTTON_TEXT}
                onClick={handleReset}
              />
            ) : undefined
          }
          topRightButton={
            <ActionLink
              className="mobile-premier-collection-filters-close-button"
              content={<CloseButtonIcon />}
              onClick={handleModalClose}
            />
          }
          className="premium-stays-availability-filter-modal mobile"
          contentClassName={
            "premium-stays-availability-filter-modal-content-wrapper"
          }
        >
          {renderFilters()}
          <MobileFloatingButton
            className="apply-filters-floating-button"
            wrapperClassName="apply-filters-floating-button-container"
            disabled={!hasValuesToApply}
            onClick={handleApply}
          >
            <Typography variant="h3" className="apply-filters-text">
              {APPLY_FILTERS_COUNT_BUTTON_TEXT(filtersToApplyCount)}
            </Typography>
          </MobileFloatingButton>
        </MobilePopoverCard>
      </>
    );
  }

  return (
    <>
      <DesktopPopupModal
        open={modalOpen}
        className="premium-stays-availability-filter-modal"
        contentClassName={
          "premium-stays-availability-filter-modal-content-wrapper"
        }
        onClose={handleModalClose}
        invisibleBackdrop={false}
      >
        <Typography variant="h3" className="filter-modal-heading">
          {FILTERS_MODAL_HEADING_TEXT}
        </Typography>
        <hr />

        {renderFilters()}

        <hr />
        <div className="filter-modal-actions">
          {canReset ? (
            <ActionButton
              message={RESET_FILTERS_BUTTON_TEXT}
              onClick={handleReset}
              className="reset-filters-button"
            />
          ) : (
            <div />
          )}
          <ActionButton
            message={APPLY_FILTERS_BUTTON_TEXT}
            onClick={handleApply}
            className="apply-filters-button"
            disabled={!hasValuesToApply}
          />
        </div>
      </DesktopPopupModal>
      <ActionButton
        className="premium-stays-all-filters-modal-button"
        defaultStyle="h4r-secondary"
        message={`${FILTERS_MODAL_CTA_TEXT}${
          outerFiltersCount > 0 ? ` (${outerFiltersCount})` : ""
        }`}
        icon={<Icon name={IconName.Settings} />}
        onClick={handleModalOpen}
      />
    </>
  );
};
