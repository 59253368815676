import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { VacationRentalShopCallState } from "../index";
import {
  ITrackingProperties,
  ObfuscatedAddress,
  ViewedVacationRentalDetailsProperties,
  ListingCollectionEnum,
} from "redmond";
import { getViewedVacationRentalListProperties } from "../../../availability/reducer";
import { getSelectedAccount } from "../../../rewards/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import { getAdultsCount, getChildrenCount, getFromDate, getPetsCount, getUntilDate } from "../../../search/reducer";
import dayjs from "dayjs";

export const getVacationRentalShopSelectedListing = (state: IStoreState) =>
  state.vacationRentalShop.selectedHome;

export const getVacationRentalShopSelectedListingCalendar = (
  state: IStoreState
) => state.vacationRentalShop.selectedHomeCalendar;

export const getVacationRentalShopUnavailableDates = (state: IStoreState) =>
  getVacationRentalShopSelectedListingCalendar(state)
    ?.calendarDays?.filter(
      (calendarDay) =>
        !calendarDay.isAvailable && !calendarDay.isAvailableForCheckout
    )
    .map((calendarDay) => dayjs(calendarDay.date).toDate());

export const getNotifyIfShopAndBookPriceDiffer = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalNotifyIfShopAndBookPriceDiffer;

export const getVacationRentalShopSelectedListingId = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalListingId;

export const getVacationRentalShopCallState = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallState;

export const getVacationRentalShopCallError = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallError;

export const hasVacationRentalShopFailed = createSelector(
  getVacationRentalShopCallState,
  (vrShopCallState) => {
    return vrShopCallState === VacationRentalShopCallState.Failed;
  }
);

export const getViewedVacationRentalDetailsProperties = createSelector(
  getViewedVacationRentalListProperties,
  getSelectedAccount,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
  getVacationRentalShopSelectedListing,
  (
    viewedPremierCollectionListProperties,
    account,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
    listing,
  ): ITrackingProperties<ViewedVacationRentalDetailsProperties> => {
    return {
      properties: {
        ...viewedPremierCollectionListProperties.properties,
        check_in_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        check_out_date: untilDate ? dayjs(untilDate).format("YYYY-MM-DD") : "",
        adults_count: adultsCount,
        children_count: childrenCount,
        pets_count: petsCount,
        pets_searched: petsCount,
        advance: dayjs(fromDate).diff(dayjs(), "day"),
        account_type_selected: account?.productDisplayName || "",
        ...listing?.listing.trackingProperties?.properties,
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        number_of_photos: listing?.listing.content.media.length,
        home_city: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.city,
        home_host_name: listing?.listing.hostInfo?.hostName,
        home_id: listing?.listingId.id,
        product_type: [ListingCollectionEnum.Premier, ListingCollectionEnum.Lifestyle].includes(listing?.listing.listingCollection || ListingCollectionEnum.NoCollection) ? listing?.listing.listingCollection as string : "",
        home_state: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.state,
      },
      encryptedProperties: [
        ...viewedPremierCollectionListProperties.encryptedProperties,
        listing?.listing.trackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

// Travel Wallet
export const showOfferBasedOnSelectedListingSelector = createSelector(
  getVacationRentalShopSelectedListing,
  getTravelWalletCredit,
  (selectedListing, credit): boolean => {
    return (
      !!selectedListing?.bestOffer &&
      ((!!credit &&
        Math.abs(credit.amount.amount) ===
          Math.abs(selectedListing.bestOffer.amount.amount)) ||
        !!selectedListing.bestOffer.availabilityPageBanner)
    );
  }
);
