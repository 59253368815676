import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import clsx from "clsx";
import {
  CarouselGallery,
  HotelCarousel,
  HotelShopRoomTypePickerVariant,
  PageTabNavigations,
  PremierCollectionShopChooseRoomSkeleton,
  PremierCollectionShopDetailsSkeleton,
  PremiumStaysInfoAccordian,
} from "halifax";
import * as textConstants from "./textConstants";
import { DesktopRewardsHeader } from "../../../rewards/components";
import {
  AVAILABLE,
  FINTECH_HOTEL_UX_UPDATED,
  getExperimentVariant,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  HOTELS_SHOP_IMAGE_MODAL,
  useExperiments,
} from "../../../../context/experiments";
import { RouteComponentProps } from "react-router";
import { DesktopShopConnectorProps } from "./container";
import "./styles.scss";
import { DesktopPremierCollectionShopChooseRoom } from "../DesktopPremierCollectionShopChooseRoom";
import { DesktopRoomPicker } from "../DesktopRoomPicker";
import { HotelShopProgressBar } from "../HotelShopProgressBar";
import {
  AmenityEnum,
  CancellationReason,
  Lodging,
  LodgingAddressExact,
} from "redmond";
import { useInView } from "react-intersection-observer";
import { ShopErrorModal } from "../ShopErrorModal";
import { DesktopHotelPhotoModal } from "../DesktopHotelPhotoModal";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../../textConstants";
import { DesktopEarnBanner } from "../DesktopEarnBanner";
import { ShopHotelDescription } from "../ShopHotelDescription";
import { TripAdvisorReviews } from "../TripAdvisorReviews";
import { PopularAmenities } from "../PopularAmenities";
import { PATH_HOME } from "../../../../utils/paths";

export interface IDesktopShopProps
  extends DesktopShopConnectorProps,
    RouteComponentProps {
  hideRightColumn?: boolean;
  hideProgressbar?: boolean;
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: () => void;
  variant?: "default" | "lifestyle-collection";
}

export const DesktopShop = ({
  history,
  lodging,
  hotelShopCallInProgress,
  hasFailed,
  isCustomizePageEnabled,
  hideRightColumn,
  hideProgressbar,
  roomInfoProductsType,
  handleReadyToRedirect,
  variant = "default",
  cancellationSummary,
  isShopCallFromShopDetails,
  shopCallFailureUrl,
  shopCallFromShopDetails,
}: IDesktopShopProps) => {
  const [clickedSectionId, setClickedSectionId] = React.useState<string>();
  const [galleryRef, galleryInView] = useInView({ threshold: 0.8 });
  const [aboutRef, aboutInView] = useInView({ threshold: 0.8 });
  const [, amenitiesInView] = useInView({ threshold: 0.8 });
  const [, reviewsInView] = useInView({ threshold: 0.8 });
  const [chooseRoomRef, chooseRoomInView] = useInView({ threshold: 0.3 });
  const expState = useExperiments();
  // base state is true to handle the case where hasFailed is true from a previous shop call
  const [hasFailedFromShopDetailsBefore, setHasFailedFromShopDetailsBefore] =
    React.useState<boolean>(true);

  const hotelShopImageModal = getExperimentVariant(
    expState.experiments,
    HOTELS_SHOP_IMAGE_MODAL
  );
  const isHotelShopImageModal = useMemo(
    () => hotelShopImageModal === AVAILABLE,
    [hotelShopImageModal]
  );

  const fintechHotelUpdatedUXExperiment = getExperimentVariant(
    expState.experiments,
    FINTECH_HOTEL_UX_UPDATED
  );
  const isFintechHotelUpdatedUXExperiment = useMemo(
    () => fintechHotelUpdatedUXExperiment === AVAILABLE,
    [fintechHotelUpdatedUXExperiment]
  );

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const selectedTab = React.useMemo(() => {
    if (
      galleryInView &&
      (clickedSectionId ? clickedSectionId === "#gallery-section" : true)
    ) {
      return 0;
    }
    if (
      aboutInView &&
      (clickedSectionId ? clickedSectionId === "#about-section" : true)
    ) {
      return 1;
    }
    if (
      amenitiesInView &&
      (clickedSectionId ? clickedSectionId === "#amenities-section" : true)
    ) {
      return 2;
    }
    if (
      reviewsInView &&
      (clickedSectionId ? clickedSectionId === "#reviews-section" : true)
    ) {
      return 3;
    }
    if (
      chooseRoomInView &&
      (clickedSectionId ? clickedSectionId === "#choose-room-section" : true)
    ) {
      return lodging &&
        lodging.lodging?.tripAdvisorReviews &&
        lodging.lodging.tripAdvisorReviews?.comments?.length > 0
        ? 4
        : 3;
    }

    return undefined;
  }, [
    galleryInView,
    aboutInView,
    amenitiesInView,
    reviewsInView,
    chooseRoomInView,
  ]);
  const scrollToSection = (sectionId: string) => {
    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT =
      document.querySelector(".app-header")?.getBoundingClientRect().height ??
      0;
    const MARGIN = 20;
    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);
    const element =
      sectionId === "choose-room-section"
        ? document.getElementsByClassName("reserve-cta")?.[0]
        : document.getElementById(sectionId);

    const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;
    setClickedSectionId(sectionId);
    setTimeout(() => {
      setClickedSectionId(undefined);
    }, 500);
    if (sectionId === "choose-room-section" && element) {
      element?.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const tabs = [
    {
      label: "Gallery",
      onClick: () => scrollToSection("gallery-section"),
    },
    { label: "About", onClick: () => scrollToSection("about-section") },
    {
      label: "Amenities",
      onClick: () => scrollToSection("amenities-section"),
    },
    ...(lodging &&
    lodging.lodging?.tripAdvisorReviews &&
    lodging.lodging.tripAdvisorReviews?.comments?.length > 0
      ? [
          {
            label: "Reviews",
            onClick: () => scrollToSection("reviews-section"),
          },
        ]
      : []),
    {
      label: "Choose Room",
      onClick: () => scrollToSection("choose-room-section"),
    },
  ];

  useEffect(() => {
    if (lodging && !!lodging.lodging.amenities.length) {
      const paidBreakfastIndex = lodging.lodging?.amenities.findIndex(
        (amenity) => amenity.amenity === AmenityEnum.PaidBreakfast
      );

      if (paidBreakfastIndex && paidBreakfastIndex > -1) {
        lodging.lodging?.amenities.splice(paidBreakfastIndex, 1);
      }
    }
  }, [lodging]);

  // if a /shop call that originated from the /shop-details page failed, the user should be redirected back
  useEffect(() => {
    if (
      hasFailed &&
      isShopCallFromShopDetails &&
      !hasFailedFromShopDetailsBefore
    ) {
      shopCallFailureUrl
        ? history.push(shopCallFailureUrl)
        : history.push(PATH_HOME);
      shopCallFromShopDetails(false, "");
      setHasFailedFromShopDetailsBefore(true);
    }
  }, [
    hasFailed,
    isShopCallFromShopDetails,
    hasFailedFromShopDetailsBefore,
    shopCallFailureUrl,
    history,
  ]);

  // if the shop call has failed previously hasFailed will be true. this is needed to ensure we only redirect
  // after a new shop call has been attempted
  useEffect(() => {
    if (hotelShopCallInProgress) {
      setHasFailedFromShopDetailsBefore(false);
    }
  }, [hotelShopCallInProgress]);

  const renderSkeleton = () => (
    <Box className="desktop-pc-shop-skeleton">
      <div id="gallery-section">
        <HotelCarousel isSkeleton />
      </div>
      <div
        className={"section-wrapper description-and-date-picker-section"}
        id="about-section"
      >
        <Box className="description-wrapper">
          <PremierCollectionShopDetailsSkeleton />
        </Box>

        <PremierCollectionShopChooseRoomSkeleton />
      </div>
    </Box>
  ); // note: skeleton still needs to be built

  const renderShop = () => {
    const { lodging: lodgingData } = lodging || {};

    return (
      lodging && (
        <Box
          className={clsx("desktop-pc-shop-container", {
            "lifestyle-collection": variant === "lifestyle-collection",
          })}
        >
          <div id="gallery-section" ref={galleryRef}>
            {isHotelShopImageModal ? (
              <DesktopHotelPhotoModal />
            ) : (
              <CarouselGallery
                modalTitle={lodgingData?.name || ""}
                media={lodgingData?.media || []}
                className="b2b"
                modalSubtitle={
                  (lodging.lodging.address as LodgingAddressExact).line1 || ""
                }
              />
            )}
          </div>

          <PageTabNavigations
            navItems={tabs}
            selectedTab={selectedTab}
            className="pc-shop-navigation thin"
          />
          <div
            className={"section-wrapper description-and-date-picker-section"}
            id="about-section"
            ref={aboutRef}
          >
            <ShopHotelDescription />
            <DesktopPremierCollectionShopChooseRoom variant={variant} />
          </div>
          <PopularAmenities />
          {lodgingData?.tripAdvisorReviews &&
            lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
              <TripAdvisorReviews lodgingData={lodgingData} />
            )}
          <DesktopEarnBanner />
          <div
            id="choose-room-section"
            className={clsx("pc-shop-room-type-section-wrapper-redesign", {
              "include-taxes-and-fees": (lodging as Lodging).price
                ?.displayTaxesAndFeesIncluded,
            })}
            ref={chooseRoomRef}
          >
            <Typography className="choose-bed-type-header" variant="h2">
              {textConstants.CHOOSE_ROOM}
            </Typography>
            <DesktopRoomPicker
              roomInfoProductsType={roomInfoProductsType}
              handleReadyToRedirect={handleReadyToRedirect}
              nonRefundablePolicyOverrideText={
                showFree24HourCancel
                  ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                  : undefined
              }
              isFintechHotelUpdatedUxEnabled={isFintechHotelUpdatedUXExperiment}
            />
            <PremiumStaysInfoAccordian variant="only-experience-credit" />
          </div>
        </Box>
      )
    );
  };

  return hasFailed ? (
    <ShopErrorModal />
  ) : (
    <Box
      className={clsx("desktop-pc-shop-wrapper", {
        "lifestyle-collection": variant.includes("lifestyle-collection"),
      })}
    >
      {!hideProgressbar && isCustomizePageEnabled && (
        <Box className="progress-bar-container">
          <HotelShopProgressBar useCustomizeStep={true} />
        </Box>
      )}
      {!hideRightColumn && <DesktopRewardsHeader />}
      {hotelShopCallInProgress ? renderSkeleton() : renderShop()}
    </Box>
  );
};
