import {Lodging, AmenityEnum, HotelStarRatingEnum, MealPlanKindEnum, LodgingCollectionEnum} from "redmond";
import { isEqual, intersection } from "lodash-es";
import { initialFilterState } from "..";

export const performAmenitiesFilter = (
  lodging: Lodging,
  amenitiesFilter: AmenityEnum[]
): boolean => {
  if (amenitiesFilter.length > 0) {
    const amenitiesList = lodging.lodging.amenities.map(
      (status) => status.amenity
    );

    if (!isEqual(intersection(amenitiesFilter, amenitiesList), amenitiesFilter))
      return false;
  }

  return true;
};

export const performStarRatingsFilter = (
  lodging: Lodging,
  starRatingsFilter: HotelStarRatingEnum[]
): boolean =>
  starRatingsFilter.length === 0 ||
  starRatingsFilter.includes(lodging.lodging.starRating);

export const performMaxPriceFilter = (lodging: Lodging, maxPrice: number): boolean =>
  maxPrice === initialFilterState.maxPrice ||
  !lodging.available ||
  lodging.price == null ||
  lodging.price.nightlyPrice.fiat.value > maxPrice;

export const performFreeCancellationFilter = (
  lodging: Lodging,
  freeCancellation: boolean
): boolean => !freeCancellation || lodging.isFreeCancel;

export const performHotelsOnSaleFilter = (
  lodging: Lodging,
  hotelsOnSale: boolean
): boolean => !hotelsOnSale || lodging.bestPromotionThisLodging != null;

export const performHotelNameFilter = (
  lodging: Lodging,
  hotelName: string
): boolean => {
  if (!lodging.lodging.name.toLowerCase().includes(hotelName.toLowerCase())) {
    return false;
  }

  return true;
};

export const performHotelsMealPlanTypeFilter = (
  lodging: Lodging,
  selectedMealPlanTypes: MealPlanKindEnum[]
): boolean => {
  if (selectedMealPlanTypes.length === 0) {
    return true;
  }

  if (
      /* If lodging is premier collection, ignore this filter for Breakfast plan as breakfast is included in all PC */
      lodging.lodgingCollection === LodgingCollectionEnum.Premier &&
      selectedMealPlanTypes.includes(MealPlanKindEnum.Breakfast)
  ) {
    return true;
  }
  return selectedMealPlanTypes.includes(
      lodging.price?.mealPlan?.kind || MealPlanKindEnum.RoomOnly
  );
}
