import { put, putResolve } from "redux-saga/effects";
import {
  HotelDetailsResponseEnum,
  IHotelDetailsResponseBase,
  HotelDetailsResponseSuccess,
} from "redmond";

import Logger from "../../../utils/logger";
import { fetchHotelDetails } from "../../../api/v0/details/fetchHotelDetails";
import {
  PremierCollectionShopDetailsCallError,
  PremierCollectionShopDetailsCallState,
} from "../reducer";
import { actions } from "../actions";
import { parseShopDetailsQueryString } from "../utils/queryStringHelpers";

export default function* fetchPremiereCollectionHotelDetailsSaga(
  fetchPremierCollectionShopDetailsAction: actions.IFetchPremierCollectionShopDetails
) {
  try {
    let parsedQueryString = parseQueryString(
      fetchPremierCollectionShopDetailsAction
    );
    const response: IHotelDetailsResponseBase = yield fetchHotelDetails(
      parsedQueryString.lodgingId
    );

    if (response.Response === HotelDetailsResponseEnum.Failure) {
      yield put(
        actions.setPremierCollectionShopDetailsCallStateFailed({
          premierCollectionShopDetailsCallError:
            PremierCollectionShopDetailsCallError.Unknown,
        })
      );
      return;
    }
    if (response.Response === HotelDetailsResponseEnum.Success) {
      const detailsResponse: HotelDetailsResponseSuccess = response;
      yield putResolve(actions.selectLodging(detailsResponse.value.details));
      yield putResolve(
        actions.setPremierCollectionShopDetailsResults({
          premierCollectionShopDetailsCallState:
            PremierCollectionShopDetailsCallState.Success,
          payload: {
            lodgingData: detailsResponse.value.details,
          },
        })
      );
    }
  } catch (e) {
    Logger.debug(e);
    yield put(
      actions.setPremierCollectionShopDetailsCallStateFailed({
        premierCollectionShopDetailsCallError:
          PremierCollectionShopDetailsCallError.Unknown,
      })
    );
  }
}

function parseQueryString(
  fetchPremierCollectionShopDetailsAction: actions.IFetchPremierCollectionShopDetails
) {
  const queryString =
    fetchPremierCollectionShopDetailsAction.history.location.search;
  return parseShopDetailsQueryString(queryString);
}
