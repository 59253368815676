import { ensureExhaustive, exhaustiveReducer } from "halifax";
import {
  AmenityEnum,
  AvailabilityResponse,
  AvailabilityResponseEnum,
  BoundingBox,
  CallState,
  HotelStarRatingEnum,
  IResult,
  ListingLocation,
  LodgingId,
  LodgingLocation,
  MealPlanKindEnum,
  ObfuscatedAddress,
  Payment,
  VacationRentalAmenityKindEnum,
  VacationRentalsAvailabilityResponse,
} from "redmond";

import { actionTypes, actions } from "../actions";

export interface IPremierCollectionFilterState {
  amenities: AmenityEnum[];
  starRatings: HotelStarRatingEnum[];
  maxPrice: number;
  freeCancellation: boolean;
  hotelsOnSale: boolean;
  hotelName: string;
  mealPlan: MealPlanKindEnum[];
}

export const initialFilterState: IPremierCollectionFilterState = {
  amenities: [],
  starRatings: [],
  maxPrice: 0,
  hotelName: "",
  freeCancellation: false,
  hotelsOnSale: false,
  mealPlan: [],
};

export enum PremierCollectionAvailabilitySortOption {
  Recommended = "Recommended",
  PricingASC = "PricingASC",
  PricingDESC = "PricingDESC",
}

export enum VacationRentalsRoomCountOptionValue {
  MIN_BEDS = "min_beds",
  MIN_BEDROOMS = "min_bedrooms",
  MIN_BATHROOMS = "min_bathrooms",
  MIN_GUESTS = "min_guests",
}

export interface IVacationRentalsFilterState {
  vacationRentalsRoomCounts: VacationRentalsRoomCounts;
  vacationRentalsAmenities: VacationRentalAmenityKindEnum[];
}

export const initialVacationRentalsFilterState: IVacationRentalsFilterState = {
  vacationRentalsRoomCounts: {
    [VacationRentalsRoomCountOptionValue.MIN_BEDS]: 0,
    [VacationRentalsRoomCountOptionValue.MIN_BEDROOMS]: 0,
    [VacationRentalsRoomCountOptionValue.MIN_BATHROOMS]: 0,
    [VacationRentalsRoomCountOptionValue.MIN_GUESTS]: 0,
  },
  vacationRentalsAmenities: [],
};

export interface VacationRentalsRoomCounts {
  [VacationRentalsRoomCountOptionValue.MIN_BEDS]: number;
  [VacationRentalsRoomCountOptionValue.MIN_BEDROOMS]: number;
  [VacationRentalsRoomCountOptionValue.MIN_BATHROOMS]: number;
  [VacationRentalsRoomCountOptionValue.MIN_GUESTS]: number;
}

export interface IPremierCollectionAvailabilityState
  extends IPremierCollectionFilterState,
    IVacationRentalsFilterState {
  availabilityResponse?: AvailabilityResponse;
  PremierCollectionAvailabilityCallState: PremierCollectionAvailabilityCallState;

  mapSearchQuery: string;
  mapBound?: Omit<BoundingBox, "LocationDescriptor">;
  searchLocation: LodgingLocation | ListingLocation | null;
  searchLodgingIds: LodgingId[] | null;

  searchFromDate: Date | null;
  searchUntilDate: Date | null;
  searchLocationResult: IResult | null;

  propertyIdInFocus: string | null;
  propertyIdHovered: string | null;

  sortOption: PremierCollectionAvailabilitySortOption;

  paymentMethods: Payment[];
  listPaymentMethodCallState: CallState;

  // The datesPicker popup needs to be controllable through different components
  openDatesModal: boolean;
  selectedLodgingIndex: number | null;
  searchAdultsCount: number;
  searchChildrenCount: number[];
  searchPetsCount: number;
  vacationRentalsAvailabilityResponse?: VacationRentalsAvailabilityResponse;
  VacationRentalsAvailabilityCallState: PremierCollectionAvailabilityCallState;
}

export enum PremierCollectionAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch returns empty lodgings
  Complete = "Complete",
  Failed = "Failed",
}

export const initialState: IPremierCollectionAvailabilityState = {
  PremierCollectionAvailabilityCallState:
    PremierCollectionAvailabilityCallState.NotCalled,

  mapBound: undefined,
  searchLocation: null,
  searchLodgingIds: null,

  searchFromDate: null,
  searchUntilDate: null,
  searchLocationResult: null,

  propertyIdInFocus: null,
  propertyIdHovered: null,

  sortOption: PremierCollectionAvailabilitySortOption.Recommended,
  mapSearchQuery: "",

  openDatesModal: false,
  selectedLodgingIndex: null,
  searchAdultsCount: 2,
  searchChildrenCount: [],
  searchPetsCount: 0,
  VacationRentalsAvailabilityCallState:
    PremierCollectionAvailabilityCallState.NotCalled,
  paymentMethods: [],
  listPaymentMethodCallState: CallState.NotCalled,
  ...initialFilterState,
  ...initialVacationRentalsFilterState,
};

export const reducer = exhaustiveReducer(
  initialState,
  (state, action: actions.PremierCollectionAvailabilityActions) => {
    switch (action.type) {
      case actionTypes.FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY:
        return {
          ...state,
          ...initialFilterState,
          ...initialVacationRentalsFilterState,
          searchLocation:
            action.mapBounds == null ? state.searchLocation : null,
          searchLocationResult:
            action.mapBounds == null ? state.searchLocationResult : null,
          mapBound: action.mapBounds,
          availabilityResponse: undefined,
          PremierCollectionAvailabilityCallState:
            PremierCollectionAvailabilityCallState.InitialSearchCallInProcess,
        };
      case actionTypes.FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY:
        return {
          ...state,
          availabilityResponse: state.availabilityResponse,
          PremierCollectionAvailabilityCallState:
            PremierCollectionAvailabilityCallState.FollowUpSearchCallInProcess,
        };

      case actionTypes.SET_PREMIER_COLLECTION_AVAILABILITY_RESULTS:
        const hasCompletedRequest = !action.payload.nextPageToken;
        const isLodgingsEmpty = action.payload.lodgings.length === 0;

        if (
          action.payload.AvailabilityResponse ===
          AvailabilityResponseEnum.Initial
        ) {
          return {
            ...state,
            availabilityResponse: action.payload,
            searchLocation: isLodgingsEmpty
              ? state.searchLocation
              : action.payload.lodgings[0].lodging.location,
            PremierCollectionAvailabilityCallState: hasCompletedRequest
              ? PremierCollectionAvailabilityCallState.Complete
              : PremierCollectionAvailabilityCallState.InitialSearchCallSuccess,
          };
        }

        return {
          ...state,
          availabilityResponse: {
            ...action.payload,
            // each FollowUpSearch fetches more hotels based on the specified page size;
            // the new lodging result continues from where the previous call ends.
            lodgings: [
              ...(state.availabilityResponse?.lodgings || []),
              ...action.payload.lodgings,
            ],
          },
          searchLocation: isLodgingsEmpty
            ? state.searchLocation
            : action.payload.lodgings[0].lodging.location,
          PremierCollectionAvailabilityCallState: hasCompletedRequest
            ? PremierCollectionAvailabilityCallState.Complete
            : PremierCollectionAvailabilityCallState.FollowUpSearchCallSuccess,
        };

      case actionTypes.SET_PREMIER_COLLECTION_AVAILABILITY_CALL_STATE_FAILED:
        return {
          ...state,
          PremierCollectionAvailabilityCallState:
            PremierCollectionAvailabilityCallState.Failed,
        };

      case actionTypes.SET_AMENITIES_FILTER:
        return {
          ...state,
          amenities: [...action.amenities],
        };

      case actionTypes.SET_STAR_RATINGS_FILTER:
        return {
          ...state,
          starRatings: [...action.starRatings],
        };

      case actionTypes.SET_MAX_PRICE_FILTER:
        return {
          ...state,
          maxPrice: action.maxPrice,
        };

      case actionTypes.SET_PREMIER_COLLECTION_CANCELLATION_FILTER:
        return {
          ...state,
          freeCancellation: action.freeCancellation,
        };

      case actionTypes.SET_PREMIER_COLLECTION_HOTELS_ON_SALE_FILTER:
        return {
          ...state,
          hotelsOnSale: action.hotelsOnSale,
        };

      case actionTypes.SET_PREMIER_COLLECTION_NAME_FILTER:
        return {
          ...state,
          hotelName: action.hotelName,
        };
      case actionTypes.SET_PREMIER_HOTELS_MEAL_PLAN_TYPES_FILTER:
        return {
          ...state,
          mealPlan: action.mealPlan,
        };
      case actionTypes.SET_PREMIER_COLLECTION_SORT_OPTION:
        return {
          ...state,
          sortOption: action.sortOption,
        };

      case actionTypes.SET_PROPERTY_ID_IN_FOCUS:
        return {
          ...state,
          propertyIdInFocus: action.propertyId,
          propertyIdHovered: action.propertyId,
        };

      case actionTypes.SET_PROPERTY_ID_HOVERED:
        return {
          ...state,
          propertyIdHovered: action.propertyId,
        };

      case actionTypes.SET_MAP_BOUND:
        return {
          ...state,
          mapBound: action.mapBound,
        };

      case actionTypes.SET_OPEN_DATES_MODAL:
        return {
          ...state,
          openDatesModal: action.openDatesModal,
        };

      case actionTypes.SET_SEARCHED_DATES:
        return {
          ...state,
          searchFromDate: action.searchedFromDate,
          searchUntilDate: action.searchedUntilDate,
        };

      case actionTypes.SET_SEARCHED_LOCATION_RESULT:
        return {
          ...state,
          searchLocationResult: action.searchedLocationResult,
          mapSearchQuery: "",
        };
      case actionTypes.SET_SELECTED_LODGING_INDEX:
        return {
          ...state,
          selectedLodgingIndex: action.index,
        };
      case actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS:
        const { adults, children, pets } = action.counts;
        return {
          ...state,
          searchAdultsCount: adults,
          searchChildrenCount: children,
          searchPetsCount: pets ?? 0,
        };

      case actionTypes.FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY:
        return {
          ...state,
          ...initialFilterState,
          ...initialVacationRentalsFilterState,
          vacationRentalsAvailabilityResponse: undefined,
          VacationRentalsAvailabilityCallState:
            PremierCollectionAvailabilityCallState.InitialSearchCallInProcess,
        };
      case actionTypes.FETCH_MORE_VACATION_RENTALS_AVAILABILITY:
        return {
          ...state,
          vacationRentalsAvailabilityResponse:
            state.vacationRentalsAvailabilityResponse,
          VacationRentalsAvailabilityCallState:
            PremierCollectionAvailabilityCallState.FollowUpSearchCallInProcess,
        };

      case actionTypes.SET_VACATION_RENTALS_AVAILABILITY_RESULTS:
        const hasCompletedVRRequest = !action.payload.nextPageToken?.token;
        const isListingsEmpty = action.payload.listings.length === 0;
        const searchedCityInResponse = action.payload.listings.find(vr => {
          const city = (vr.listing.content.location.address as ObfuscatedAddress).city;
          return city && state.searchLocationResult?.label.toLowerCase().includes(city.toLowerCase());
        });
        if (action.isInitialRequest) {
          return {
            ...state,
            vacationRentalsAvailabilityResponse: action.payload,
            mapBound: undefined,
            // Look to see if the exact city the user searched for is in the response. If not default to the first location in the response
            searchLocation: isListingsEmpty
              ? state.searchLocation
              : (searchedCityInResponse ? searchedCityInResponse.listing.content.location : action.payload.listings[0].listing.content.location),
            VacationRentalsAvailabilityCallState: hasCompletedVRRequest
              ? PremierCollectionAvailabilityCallState.Complete
              : PremierCollectionAvailabilityCallState.InitialSearchCallSuccess,
          };
        }
        const currentCity = ((state.searchLocation as ListingLocation).address as ObfuscatedAddress).city;
        const locationAlreadyFound = currentCity && state.searchLocationResult?.label.toLowerCase().includes(currentCity.toLowerCase());
        return {
          ...state,
          vacationRentalsAvailabilityResponse: {
            ...action.payload,
            listings: [
              ...(state.vacationRentalsAvailabilityResponse?.listings || []),
              ...action.payload.listings,
            ],
          },
          // For some VR locations, the subsequent search results are in a different city that the search location. If that is the case
          // default to the initial search location. Without this logic the map will move to the second search location. In the case where
          // the desired location is not in the initial response and it is in the subsequent response, the map will move to the second location
          searchLocation: isListingsEmpty || locationAlreadyFound || !searchedCityInResponse
            ? state.searchLocation
            : searchedCityInResponse.listing.content.location,
          VacationRentalsAvailabilityCallState: hasCompletedVRRequest
            ? PremierCollectionAvailabilityCallState.Complete
            : PremierCollectionAvailabilityCallState.FollowUpSearchCallSuccess,
        };

      case actionTypes.SET_VACATION_RENTALS_AVAILABILITY_CALL_STATE_FAILED:
        return {
          ...state,
          VacationRentalsAvailabilityCallState:
            PremierCollectionAvailabilityCallState.Failed,
        };

      case actionTypes.SET_VACATION_RENTALS_ROOM_COUNTS:
        return {
          ...state,
          vacationRentalsRoomCounts: action.counts,
        };

      case actionTypes.SET_VACATION_RENTALS_AMENITIES:
        return {
          ...state,
          vacationRentalsAmenities: action.amenities,
        };

      case actionTypes.SET_MAP_SEARCH_QUERY:
        return {
          ...state,
          mapSearchQuery: action.query,
        };

      case actionTypes.LIST_PAYMENT_METHODS:
        return {
          ...state,
          listPaymentMethodCallState: CallState.InProcess,
        };

      case actionTypes.SET_PAYMENT_METHODS:
        return {
          ...state,
          paymentMethods: action.paymentMethods,
          listPaymentMethodCallState: CallState.Success,
        };

      case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
        return {
          ...state,
          listPaymentMethodCallState: CallState.Failed,
        };

      default:
        return ensureExhaustive(action);
    }
  }
);

export * from "./selectors";
