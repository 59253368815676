export const FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY =
  "premierCollectionAvailability/FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY";
export type FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY =
  typeof FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY;

export const FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY =
  "premierCollectionAvailability/FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY";
export type FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY =
  typeof FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY;

export const SET_PREMIER_COLLECTION_AVAILABILITY_RESULTS =
  "premierCollectionAvailability/SET_PREMIER_COLLECTION_AVAILABILITY_RESULTS";
export type SET_PREMIER_COLLECTION_AVAILABILITY_RESULTS =
  typeof SET_PREMIER_COLLECTION_AVAILABILITY_RESULTS;

export const SET_PREMIER_COLLECTION_AVAILABILITY_CALL_STATE_FAILED =
  "premierCollectionAvailability/SET_PREMIER_COLLECTION_AVAILABILITY_CALL_STATE_FAILED";
export type SET_PREMIER_COLLECTION_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_PREMIER_COLLECTION_AVAILABILITY_CALL_STATE_FAILED;

export const SET_PROPERTY_ID_IN_FOCUS =
  "premierCollectionAvailability/SET_PROPERTY_ID_IN_FOCUS";
export type SET_PROPERTY_ID_IN_FOCUS = typeof SET_PROPERTY_ID_IN_FOCUS;

export const SET_PROPERTY_ID_HOVERED =
  "premierCollectionAvailability/SET_PROPERTY_ID_HOVERED";
export type SET_PROPERTY_ID_HOVERED = typeof SET_PROPERTY_ID_HOVERED;

export const SET_AMENITIES_FILTER =
  "premierCollectionAvailability/SET_AMENITIES_FILTER";
export type SET_AMENITIES_FILTER = typeof SET_AMENITIES_FILTER;

export const SET_STAR_RATINGS_FILTER =
  "premierCollectionAvailability/SET_STAR_RATINGS_FILTER";
export type SET_STAR_RATINGS_FILTER = typeof SET_STAR_RATINGS_FILTER;

export const SET_MAX_PRICE_FILTER =
  "premierCollectionAvailability/SET_MAX_PRICE_FILTER";
export type SET_MAX_PRICE_FILTER = typeof SET_MAX_PRICE_FILTER;

export const SET_PREMIER_COLLECTION_NAME_FILTER =
  "premierCollectionAvailability/SET_PREMIER_COLLECTION_NAME_FILTER";
export type SET_PREMIER_COLLECTION_NAME_FILTER =
  typeof SET_PREMIER_COLLECTION_NAME_FILTER;

export const SET_PREMIER_HOTELS_MEAL_PLAN_TYPES_FILTER =
    "premierCollectionAvailability/SET_PREMIER_HOTELS_MEAL_PLAN_TYPES_FILTER";
export type SET_PREMIER_HOTELS_MEAL_PLAN_TYPES_FILTER = typeof SET_PREMIER_HOTELS_MEAL_PLAN_TYPES_FILTER;

export const SET_PREMIER_COLLECTION_SORT_OPTION =
  "premierCollectionAvailability/SET_PREMIER_COLLECTION_SORT_OPTION";
export type SET_PREMIER_COLLECTION_SORT_OPTION =
  typeof SET_PREMIER_COLLECTION_SORT_OPTION;

export const SET_PREMIER_COLLECTION_CANCELLATION_FILTER =
  "premierCollectionAvailability/SET_PREMIER_COLLECTION_CANCELLATION_FILTER";
export type SET_PREMIER_COLLECTION_CANCELLATION_FILTER =
  typeof SET_PREMIER_COLLECTION_CANCELLATION_FILTER;

export const SET_PREMIER_COLLECTION_HOTELS_ON_SALE_FILTER =
  "premierCollectionAvailability/SET_PREMIER_COLLECTION_HOTELS_ON_SALE_FILTER";
export type SET_PREMIER_COLLECTION_HOTELS_ON_SALE_FILTER =
  typeof SET_PREMIER_COLLECTION_HOTELS_ON_SALE_FILTER;

export const SET_MAP_BOUND = "premierCollectionAvailability/SET_MAP_BOUND";
export type SET_MAP_BOUND = typeof SET_MAP_BOUND;

export const SET_SEARCH_LODGING_IDS =
  "premierCollectionAvailability/SET_SEARCH_LODGING_IDS";
export type SET_SEARCH_LODGING_IDS = typeof SET_SEARCH_LODGING_IDS;

export const SET_OPEN_DATES_MODAL =
  "premierCollectionAvailability/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_SEARCHED_DATES =
  "premierCollectionAvailability/SET_SEARCHED_DATES";
export type SET_SEARCHED_DATES = typeof SET_SEARCHED_DATES;

export const SET_SEARCHED_LOCATION_RESULT =
  "premierCollectionAvailability/SET_SEARCHED_LOCATION_RESULT";
export type SET_SEARCHED_LOCATION_RESULT = typeof SET_SEARCHED_LOCATION_RESULT;

export const SET_SELECTED_LODGING_INDEX =
  "premierCollectionAvailability/SET_SELECTED_LODGING_INDEX";
export type SET_SELECTED_LODGING_INDEX = typeof SET_SELECTED_LODGING_INDEX;

export const SET_SEARCHED_OCCUPANCY_COUNTS =
  "premierCollectionAvailability/SET_SEARCHED_OCCUPANCY_COUNTS";
export type SET_SEARCHED_OCCUPANCY_COUNTS =
  typeof SET_SEARCHED_OCCUPANCY_COUNTS;

export const FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY =
  "premierCollectionAvailability/FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY";
export type FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY =
  typeof FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY;

export const FETCH_MORE_VACATION_RENTALS_AVAILABILITY =
  "premierCollectionAvailability/FETCH_MORE_VACATION_RENTALS_AVAILABILITY";
export type FETCH_MORE_VACATION_RENTALS_AVAILABILITY =
  typeof FETCH_MORE_VACATION_RENTALS_AVAILABILITY;

export const SET_VACATION_RENTALS_AVAILABILITY_RESULTS =
  "premierCollectionAvailability/SET_VACATION_RENTALS_AVAILABILITY_RESULTS";
export type SET_VACATION_RENTALS_AVAILABILITY_RESULTS =
  typeof SET_VACATION_RENTALS_AVAILABILITY_RESULTS;

export const SET_VACATION_RENTALS_AVAILABILITY_CALL_STATE_FAILED =
  "premierCollectionAvailability/SET_VACATION_RENTALS_AVAILABILITY_CALL_STATE_FAILED";
export type SET_VACATION_RENTALS_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_VACATION_RENTALS_AVAILABILITY_CALL_STATE_FAILED;

export const SET_VACATION_RENTALS_ROOM_COUNTS =
  "premierCollectionAvailability/SET_VACATION_RENTALS_ROOM_COUNTS";
export type SET_VACATION_RENTALS_ROOM_COUNTS =
  typeof SET_VACATION_RENTALS_ROOM_COUNTS;

export const SET_VACATION_RENTALS_AMENITIES =
  "premierCollectionAvailability/SET_VACATION_RENTALS_AMENITIES";
export type SET_VACATION_RENTALS_AMENITIES =
  typeof SET_VACATION_RENTALS_AMENITIES;

export const SET_MAP_SEARCH_QUERY =
  "premierCollectionAvailability/FETCH_MAP_SEARCH_CATEGORIES";
export type SET_MAP_SEARCH_QUERY = typeof SET_MAP_SEARCH_QUERY;

export const LIST_PAYMENT_METHODS =
  "premierCollectionAvailability/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS =
  "premierCollectionAvailability/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "premierCollectionAvailability/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;
