import React from "react";

import { Box } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";

import { StayTypesEnum } from "redmond";

import { PremierCollectionSearchControl } from "../../../search/components/PremierCollectionSearchControl";
import {
  RequiredSearchParams,
  SearchParams,
  transformToStringifiedAvailabilityQuery,
} from "../../../shop/utils/queryStringHelpers";
import { AvailabilityFilter } from "../AvailabilityFilter";
import { AllFiltersModal } from "../AvailabilityFilter/components/AllFiltersModal";
import { SearchButton } from "./components";
import { AvailabilitySearchControlConnectorProps } from "./container";

import "./styles.scss";
import {
  AVAILABLE,
  getExperimentVariant,
  SHOW_MEALPLAN,
  useExperiments
} from "../../../../context/experiments";

export interface IAvailabilitySearchControlProps
  extends AvailabilitySearchControlConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  isLifestyleCollection?: boolean;
  isLCForPremiumCardHoldersEnabled?: boolean;
  isLCForNonPremiumCardHoldersEnabled?: boolean;
  doSearch?: (params: RequiredSearchParams) => void;
}

function searchParamsToQuery(params: SearchParams): string {
  return transformToStringifiedAvailabilityQuery({
    ...params,
    location: params.location?.label ?? "",
  });
}

export const PremierCollectionAvailabilitySearchControl = (
  props: IAvailabilitySearchControlProps
) => {
  const {
    lodgings,
    isLifestyleCollection,
    isLCForNonPremiumCardHoldersEnabled,
    isLCForPremiumCardHoldersEnabled,
    vrListings,
    stayType,
    searchParams,
    availabilityParams,
    doSearch,
    isMapSearch,
    setIsMegaMenuSelection,
  } = props;

  const availableLodgingCount = React.useMemo(
    () =>
      (stayType === StayTypesEnum.Hotels
        ? lodgings?.filter((lodging) => lodging.available !== false).length
        : vrListings?.length) ?? 0,
    [lodgings, vrListings, stayType]
  );

  const requiredParams = (
    params: SearchParams
  ): null | RequiredSearchParams => {
    if (
      params.fromDate == null ||
      params.untilDate == null ||
      params.location == null
    )
      return null;
    return {
      ...params,
      fromDate: params.fromDate,
      untilDate: params.untilDate,
      location: params.location,
    };
  };

  const areSearchParamsChanged = React.useMemo(() => {
    return (
      // We consider the params changed either when the query string will change, or
      // when we're currently in a map search with a non-null search location.
      (isMapSearch && searchParams.location != null) ||
      searchParamsToQuery(searchParams) !==
        searchParamsToQuery(availabilityParams)
    );
  }, [searchParams, availabilityParams]);
  const searchReadyParams = React.useMemo(
    () => requiredParams(searchParams),
    [searchParams]
  );

  const expState = useExperiments();

  const showMealPlanExperimentVariant = getExperimentVariant(
      expState.experiments,
      SHOW_MEALPLAN
  );

  const isShowMealPlanExperiment = React.useMemo(
      () => showMealPlanExperimentVariant === AVAILABLE,
      [showMealPlanExperimentVariant]
  );

  return (
    <Box
      className={clsx("premier-collection-availability-search-control-root")}
    >
      <Box className="availability-search-control-content">
        <Box className="search-and-filters">
          <PremierCollectionSearchControl
            overrideLocationName={
              isMapSearch && searchParams.location == null
                ? "Map area"
                : undefined
            }
            displaySearchOnChange={true}
            showTravelerSelection={true}
            showSearchButton={false}
            isLifestyleCollection={isLifestyleCollection}
            includesLifestyleCollection={
              isLCForPremiumCardHoldersEnabled ||
              isLCForNonPremiumCardHoldersEnabled
            }
          />
          <Box className="filters">
            {searchReadyParams != null &&
              areSearchParamsChanged &&
              doSearch != null && (
                <SearchButton
                  className={clsx("hotel-search-control-button", "b2b")}
                  message={"Search Again"}
                  onClick={() => 
                  {
                    doSearch(searchReadyParams);
                    setIsMegaMenuSelection(false);
                  }}
                />
              )}
            {availableLodgingCount > 1 && (
              <>
                <AllFiltersModal
                  showMealPlanTypeFilter={isShowMealPlanExperiment}
                />
                <AvailabilityFilter />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
