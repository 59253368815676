import queryStringParser from "query-string";
import { parseEnum } from "b2b-base/src/utils/enumCast";
import { DatelessSearchSource } from "redmond";

export interface IPremierCollectionShopDetailsParsedQuery {
  lodgingId: string;
  unavailable?: boolean;
  source: DatelessSearchSource;
}

export const parseShopDetailsQueryString = (
  url: string
): IPremierCollectionShopDetailsParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(url);
  const parsedQueryString: IPremierCollectionShopDetailsParsedQuery = {
    lodgingId: parsedQueryStringPrimitive.lodgingId as string,
    unavailable: parsedQueryStringPrimitive.unavailable === "true",
    source: parseEnum(
      DatelessSearchSource,
      parsedQueryStringPrimitive.source as string,
      DatelessSearchSource.Unknown
    ),
  };

  return parsedQueryString;
};
