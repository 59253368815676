import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { getStayType } from "../../../../../search/reducer";
import {
  setAmenitiesFilter,
  setCancellationFilter,
  setHotelsOnSaleFilter,
  setMaxPriceFilter,
  setPremierCollectionAvailabilitySortOption,
  setPremierCollectionNameFilter,
  setPremierHotelsMealPlanTypeFilter,
  setStarRatingsFilter,
  setVacationRentalsAmenities,
  setVacationRentalsRoomCounts,
} from "../../../../actions/actions";
import {
  getPremierCollectionAvailabilityAmenitiesFilter,
  getPremierCollectionAvailabilityCancellationFilter,
  getPremierCollectionAvailabilityCurrency,
  getPremierCollectionAvailabilityHotelNameFilter,
  getPremierCollectionAvailabilityHotelsMealPlanFilter,
  getPremierCollectionAvailabilityHotelsOnSaleFilter,
  getPremierCollectionAvailabilityMaxPriceFilter,
  getPremierCollectionAvailabilityMinMaxPriceRange,
  getPremierCollectionAvailabilitySortOption,
  getPremierCollectionAvailabilityStarRatingsFilter,
  getPremierCollectionHotelsNumberOfAppliedFilters,
  getVacationRentalAmenities,
  getVacationRentalRoomCounts,
  getVacationRentalsNumberOfAppliedFilters,
} from "../../../../reducer";
import { AllFiltersModal } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  sortOption: getPremierCollectionAvailabilitySortOption(state),
  roomCounts: getVacationRentalRoomCounts(state),
  currency: getPremierCollectionAvailabilityCurrency(state),
  vrAmenities: getVacationRentalAmenities(state),
  starRatings: getPremierCollectionAvailabilityStarRatingsFilter(state),
  hotelAmenities: getPremierCollectionAvailabilityAmenitiesFilter(state),
  hotelMaxPriceFilter: getPremierCollectionAvailabilityMaxPriceFilter(state),
  freeCancellationFilter:
    getPremierCollectionAvailabilityCancellationFilter(state),
  hotelMinMaxPriceRange:
    getPremierCollectionAvailabilityMinMaxPriceRange(state),
  hotelNameFilter: getPremierCollectionAvailabilityHotelNameFilter(state),
  hotelsOnSaleFilter: getPremierCollectionAvailabilityHotelsOnSaleFilter(state),
  hotelsMealPlanFilter: getPremierCollectionAvailabilityHotelsMealPlanFilter(state),
  hotelsFiltersCount: getPremierCollectionHotelsNumberOfAppliedFilters(state),
  vrFiltersCount: getVacationRentalsNumberOfAppliedFilters(state),
  stayType: getStayType(state),
});

const mapDispatchToProps = {
  setSortOption: setPremierCollectionAvailabilitySortOption,
  setRoomCounts: setVacationRentalsRoomCounts,
  setVRAmenities: setVacationRentalsAmenities,
  setHotelAmenities: setAmenitiesFilter,
  setPremierCollectionNameFilter,
  setHotelsOnSaleFilter,
  setCancellationFilter,
  setStarRatingsFilter,
  setMaxPriceFilter,
  setPremierHotelsMealPlanTypeFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AllFiltersModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAllFiltersModal = withRouter(connector(AllFiltersModal));
