import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ContactInfoWorkflow,
  IContactInfo,
  TravelerSelectStep,
  ContactInfoStep,
  Header,
  HotelNeedToKnowPanel,
  RoomDetails,
  getCancellationPolicyInfo,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  LoadingPopup,
  B2BSpinner,
  emailRegex,
  phoneRegex,
  Icon,
  IconName,
  TreesConfirmation,
  InformationalModal as TreesModal,
  TravelWalletSingleSelectionStep,
  AccordionCollection,
  ButtonWrap,
  CloseButtonIcon,
  HotelInformationalBanner,
  getIsFreeBreakfast,
  textConstants as halifaxTextConstants,
  VoidWindowNotice,
  TripPurposePanel,
} from "halifax";
import {
  ADD_CONTACT_INFO,
  CancellationPolicyEnum,
  CancellationReason,
  CheckInPolicy,
  LodgingCollectionEnum,
} from "redmond";
import { RouteComponentProps } from "react-router-dom";

import "./styles.scss";
import { MobilePremierCollectionBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  PremierCollectionBookPassengerSelection,
  PaymentCard,
  TitleSummaryCard,
  PremierCollectionBookMobileButton,
  PremierCollectionBookSummaryPanel,
  PriceBreakdownDropdown,
  AGENT_FEE,
} from "../../components";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_SUBTITLE,
  NEED_TO_KNOW_TITLE,
  NEED_TO_KNOW_DETAILS,
  getCfarSecondaryText,
  REVIEW_MY_TRIP_TEXT,
  PRICE_QUOTE_MESSAGE,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  CHECKOUT_PRICE_BREAKDOWN_HEADER,
  EXPERIENCE_CREDIT_FAQ,
  CFAR_POLICY_TITLE,
  CFAR_POLICY_SUBTITLE,
} from "./textConstants";
import { PATH_HOME, PATH_BOOK_CONFIRMATION } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import clsx from "clsx";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  goToShop,
  goToAncillaryCustomize,
} from "../../../shop/utils/queryStringHelpers";
import { fetchCustomerDetails } from "../../../../api/v0/customer/fetchCustomerDetails";
import { TravelOfferSelection } from "../TravelOfferSelection";
import queryStringParser from "query-string";
import PremierCollectionCheckoutBenefits from "../PremierCollectionCheckoutBenefits";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../../../shop/textConstants";
import {
  AVAILABLE,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import { useExperimentIsVariant } from "@capone/experiments";

export interface IMobilePremierCollectionBookWorkflowProps
  extends MobilePremierCollectionBookWorkflowConnectorProps,
    RouteComponentProps {
  isTreesModalExperiment: boolean;
  isTravelWalletOfferExperiment: boolean;
  isTravelWalletCreditsExperiment: boolean;
  isCreditAndOfferStackingExperimentV1: boolean;
  isTravelCreditHistoryExperiment: boolean;
}

enum MobilePremierCollectionBookWorkflowStep {
  TravelerInformation,
  ContactInformation,
  TravelOfferSelection,
  RewardsAndPayment,
  Review,
}

export const MobilePremierCollectionBookWorkflow = ({
  priceQuote,
  hasNoUserPassengers,
  schedulePriceQuote,
  history,
  chosenProduct,
  selectedLodging,
  roomInfoProduct,
  chosenProductIndex,
  reservation,
  isBookingValid,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDifferenceAcknowledged,
  scheduleBook,
  priceQuoteInProgress,
  priceQuoteErrors,
  confirmationDetailsErrors,
  offers,
  fetchApplicableTravelWalletItems,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  isTravelWalletPaymentOnly,
  creditToApply,
  credit,
  isAddOnOptionAvailable,
  ancillaries,
  fetchTravelWalletCreditHistory,
  hasCfarAttached,
  hotelCfarQuote,
  largestEarnAccount,
  isTreesModalExperiment,
  isTravelWalletOfferExperiment,
  isTravelWalletCreditsExperiment,
  isCreditAndOfferStackingExperimentV1,
  isTravelCreditHistoryExperiment,
  cancellationSummary,
  setTripPurpose,
}: IMobilePremierCollectionBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;
  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);
  const [checkoutStep, setCheckoutStep] =
    useState<MobilePremierCollectionBookWorkflowStep>(0);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [travelOfferSelectStep, setTravelOfferSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [updatedInfoOnReview, setUpdatedInfoOnReview] = useState(false);
  const [freeBreakfastModalOpen, setFreeBreakfastModalOpen] =
    useState<boolean>(false);
  const [nthNightModalOpen, setNthNightModalOpen] =
    useState<boolean>(false);

  const expState = useExperiments();

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = React.useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        chosenProduct?.cancellationPolicy,
        getCfarSecondaryText
      )
    : null;

  const isTripPurposeXpEnabled = useExperimentIsVariant(
    "corp-trip-purpose",
    AVAILABLE
  );

  const onBookPremierCollection = () => {
    const queryString = queryStringParser.parse(history.location.search);
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
          ancillaries: ancillaries,
        })
      : schedulePriceQuote({
          history,
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          ancillaries: ancillaries,
        });
  };

  const mergedPolicies = React.useMemo(
    () =>
      [
        ...(chosenProduct?.policies ?? []),
        ...(priceQuote?.checkInInstructions.policies ?? []),
      ].reduce((uniquePolicies, currentPolicy) => {
        if (
          !uniquePolicies.some(({ title }) => currentPolicy.title === title)
        ) {
          uniquePolicies.push(currentPolicy);
        }
        return uniquePolicies;
      }, [] as CheckInPolicy[]),
    [chosenProduct?.policies, priceQuote?.checkInInstructions.policies]
  );

  const openPremierCollectionBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openTravelOffersSelection = () => {
    setTravelOfferSelectStep(
      TravelWalletSingleSelectionStep.TravelWalletSelection
    );
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  const incrementCheckoutStep = () => {
    setCheckoutStep((step) => {
      if (
        (step === MobilePremierCollectionBookWorkflowStep.ContactInformation &&
          isCreditAndOfferStackingExperimentV1) ||
        (step === MobilePremierCollectionBookWorkflowStep.ContactInformation &&
          (isTravelWalletCreditsExperiment ? !credit : true) &&
          (isTravelWalletOfferExperiment ? !offers?.length : true)) ||
        (step ===
          MobilePremierCollectionBookWorkflowStep.TravelOfferSelection &&
          isTravelWalletPaymentOnly)
      ) {
        return step + 2;
      }
      return step === MobilePremierCollectionBookWorkflowStep.Review
        ? step
        : step + 1;
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case MobilePremierCollectionBookWorkflowStep.TravelerInformation:
        if (selectedLodging) {
          if (isAddOnOptionAvailable) {
            goToAncillaryCustomize({ history });
          } else {
            goToShop({ history });
          }
        } else {
          history.push(PATH_HOME);
        }
        break;
      case MobilePremierCollectionBookWorkflowStep.RewardsAndPayment:
        if (
          isCreditAndOfferStackingExperimentV1 ||
          (isTravelWalletOfferExperiment && offers?.length) ||
          (isTravelWalletCreditsExperiment && !credit)
        ) {
          setCheckoutStep((step) => step - 1);
        } else {
          setCheckoutStep((step) => step - 2);
        }
        break;
      case MobilePremierCollectionBookWorkflowStep.Review:
        if (isTravelWalletPaymentOnly) {
          setCheckoutStep((step) => step - 2);
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      default:
        setCheckoutStep((step) => step - 1);
        break;
    }
  };

  const closeAllPopovers = () => {
    setTravelerWorkflowStep(TravelerSelectStep.Main);
    setContactInfoStep(ContactInfoStep.Main);
    setTravelOfferSelectStep(TravelWalletSingleSelectionStep.Main);
    setOpenPaymentCard(false);
  };

  const startSchedulePriceQuoteAndResetPayment = () => {
    schedulePriceQuote({
      history,
      agentFee: isAgentPortal ? AGENT_FEE : 0,
      pollQuoteOnly: true,
      ancillaries: ancillaries,
    });
    resetPaymentCardSelectedAccounts();
    setUpdatedInfoOnReview(true);
  };

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setCheckoutStep(
        MobilePremierCollectionBookWorkflowStep.TravelerInformation
      );
    }
  }, [priceQuoteErrors.length, confirmationDetailsErrors.length]);

  useEffect(() => {
    switch (checkoutStep) {
      case MobilePremierCollectionBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openPremierCollectionBookPassengerSelection();
        break;
      case MobilePremierCollectionBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case MobilePremierCollectionBookWorkflowStep.TravelOfferSelection:
        closeAllPopovers();
        openTravelOffersSelection();
        break;
      case MobilePremierCollectionBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep ===
        MobilePremierCollectionBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  useEffect(() => {
    if (reservation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  React.useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, []);

  useEffect(() => {
    if (
      checkoutStep === MobilePremierCollectionBookWorkflowStep.Review &&
      !priceQuoteInProgress &&
      !!priceQuote &&
      updatedInfoOnReview
    ) {
      if (creditToApply) {
        setCheckoutStep(
          MobilePremierCollectionBookWorkflowStep.TravelOfferSelection
        ); // if user has selected a travel wallet item before PQ is run again on pax/contact info update, bring them back to that step
      } else {
        handleGoBack();
      }
    }
  }, [checkoutStep, priceQuoteInProgress, priceQuote, updatedInfoOnReview]);

  useEffect(() => {
    if (priceQuoteInProgress && !!priceQuote) {
      setPriceQuote(null, null);
    }
  }, [priceQuoteInProgress]);

  return (
    <>
      <Box
        className={clsx(
          "mobile-premier-collection-book-workflow-root",
          "display-room-details-modal",
          {
            "mobile-review-premier-collection-book":
              checkoutStep === MobilePremierCollectionBookWorkflowStep.Review,
            "free-breakfast":
              selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle &&
              getIsFreeBreakfast(selectedLodging?.price) &&
              !selectedLodging?.nthNightPromotion,
            "nth-night": selectedLodging?.nthNightPromotion,
          }
        )}
      >
        {/* TODO: Header should have pricing details */}
        <Header
          className={clsx("mobile-premier-collection-book-header", "checkout")}
          left={
            <BackButton
              className="mobile-premier-collection-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={
            <PriceBreakdownDropdown
              popoverClassName={
                checkoutStep === MobilePremierCollectionBookWorkflowStep.Review
                  ? "mobile-review-premier-collection-book-price-breakdown"
                  : undefined
              }
            />
          }
          isMobile={true}
          fullWidth={true}
        />
        <TitleSummaryCard isMobile />
        {showFree24HourCancel && <VoidWindowNotice />}
        <PremierCollectionBookSummaryPanel isMobile className="b2b" />
        {selectedLodging?.lodgingCollection ===
          LodgingCollectionEnum.Lifestyle &&
        getIsFreeBreakfast(selectedLodging?.price) &&
        !selectedLodging?.nthNightPromotion ? (
          <HotelInformationalBanner
            content={
              <>
                <ButtonWrap
                  className="free-breakfast-banner-tooltip"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFreeBreakfastModalOpen(true);
                  }}
                >
                  <Box className="free-breakfast-banner-content">
                    <Typography className="free-breakfast-content-text">
                      Free breakfast for cardholders
                    </Typography>
                    <Icon name={IconName.InfoCircle} />
                  </Box>
                </ButtonWrap>
                <MobilePopoverCard
                  className="free-breakfast-popover"
                  open={freeBreakfastModalOpen}
                  onClose={() => setFreeBreakfastModalOpen(false)}
                  centered
                  topRightButton={
                    <CloseButtonIcon
                      onClick={() => setFreeBreakfastModalOpen(false)}
                    />
                  }
                  headerElement={
                    <Typography
                      variant="h2"
                      className="free-breakfast-popover-header-text"
                    >
                      Free breakfast for cardholders
                    </Typography>
                  }
                >
                  <Typography
                    variant="subtitle2"
                    className="free-breakfast-popover-content"
                  >
                    {halifaxTextConstants.FREE_BREAKFAST_TOOLTIP}
                  </Typography>
                </MobilePopoverCard>
              </>
            }
            isMobile
            isMap={false}
            iconName={IconName.FreeBreakfast}
          />
        ) : null}
        {selectedLodging?.nthNightPromotion && !selectedLodging?.nthNightPromotion?.isLocked ? (
          <HotelInformationalBanner
            content={
              <>
                <ButtonWrap
                  className="nth-night-banner-tooltip"
                  onClick={(e) => {
                    e.stopPropagation();
                    setNthNightModalOpen(true);
                  }}
                >
                  <Box className="nth-night-banner-content">
                    <Typography className="nth-night-content-text">
                      {selectedLodging?.nthNightPromotion?.description}
                    </Typography>
                    <Icon name={IconName.InfoCircle} />
                  </Box>
                </ButtonWrap>
                <MobilePopoverCard
                  className="nth-night-popover"
                  open={nthNightModalOpen}
                  onClose={() => setNthNightModalOpen(false)}
                  centered
                  topRightButton={
                    <CloseButtonIcon
                      onClick={() => setNthNightModalOpen(false)}
                    />
                  }
                  headerElement={
                    <Typography
                      variant="h2"
                      className="nth-night-popover-header-text"
                    >
                      {selectedLodging?.nthNightPromotion?.description}
                    </Typography>
                  }
                >
                  <Typography
                    variant="subtitle2"
                    className="nth-night-popover-content"
                  >
                     {selectedLodging?.nthNightPromotion?.tooltipDescription}
                  </Typography>
                </MobilePopoverCard>
              </>
            }
            isMobile
            isMap={false}
            iconName={IconName.Sparkle}
          />
        ) : null}
        {!!roomInfoProduct && chosenProductIndex !== null && (
          <RoomDetails
            roomInfoProduct={roomInfoProduct}
            productIndex={chosenProductIndex}
            lodging={selectedLodging}
            isMobile
            hideAmenities
            roomsCount={1}
            hideRoomBedDescription
            hideRoomCapacity
            showAmenitiesModal
          />
        )}
        <PremierCollectionBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case MobilePremierCollectionBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case MobilePremierCollectionBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue;
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case MobilePremierCollectionBookWorkflowStep.Review:
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onReviewStep={
            checkoutStep === MobilePremierCollectionBookWorkflowStep.Review
          }
          isMobile={true}
          useLocalId={true}
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE,
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === MobilePremierCollectionBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          isMobile={true}
          contactInfo={contactInfo}
          onGoBack={() => {
            if (
              checkoutStep ===
              MobilePremierCollectionBookWorkflowStep.ContactInformation
            ) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(contactInfo) => {
            setContact(contactInfo);
            setContactInfo(contactInfo.email, contactInfo.phoneNumber);
            if (
              contactInfo.email &&
              contactInfo.phoneNumber &&
              emailRegex.test(contactInfo.email) &&
              phoneRegex.test(contactInfo.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
            incrementCheckoutStep();
          }}
          mobileHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onContinueClick={() => {
            switch (checkoutStep) {
              case MobilePremierCollectionBookWorkflowStep.Review:
                startSchedulePriceQuoteAndResetPayment();
                break;
              default:
                schedulePriceQuote({
                  history,
                  agentFee: isAgentPortal ? AGENT_FEE : 0,
                  pollQuoteOnly: true,
                  ancillaries,
                });
                break;
            }
          }}
          loading={customerDetailsLoading}
          bottomContent={
            showFree24HourCancel ? <VoidWindowNotice /> : undefined
          }
        />
        {largestEarnAccount && largestEarnAccount.earn.hotelsMultiplier && (
          <PremierCollectionCheckoutBenefits
            variant={
              selectedLodging?.lodgingCollection ===
              LodgingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "premier-collection"
            }
            largestEarnAccount={largestEarnAccount}
          />
        )}
        {isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelOfferSelectStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  MobilePremierCollectionBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelOfferSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <Box className="mobile-hotels-price-breakdown">
          <Typography className="price-breakdown-header" variant="h2">
            {CHECKOUT_PRICE_BREAKDOWN_HEADER}
          </Typography>
          <PriceBreakdown />
        </Box>

        <AccordionCollection
          accordionContents={EXPERIENCE_CREDIT_FAQ}
          expandIcon={<Icon name="plus" />}
          collapseIcon={<Icon name="minus" />}
        />
        {hasCfarAttached ? (
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "cancellation-details"
            )}
          >
            <Typography variant="h2" className="cfar-policy-title">
              <Icon name={IconName.CheckShieldBlue} />
              {CFAR_POLICY_TITLE(hotelCfarQuote?.coveragePercentage)}
            </Typography>
            <Typography variant="body2">
              {CFAR_POLICY_SUBTITLE(hotelCfarQuote?.coveragePercentage)}
            </Typography>
          </Box>
        ) : cancellationPolicyInfo ? (
          <Box className={"cancellation-details"}>
            <Typography variant="h2">
              {cancellationPolicyInfo.primaryText}
            </Typography>
            <Typography variant="body2">
              {showFree24HourCancel &&
              chosenProduct?.cancellationPolicy.CancellationPolicy ===
                CancellationPolicyEnum.NonRefundable
                ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                : cancellationPolicyInfo.secondaryText}
            </Typography>
          </Box>
        ) : null}
        <HotelNeedToKnowPanel
          title={NEED_TO_KNOW_TITLE}
          subtitle={NEED_TO_KNOW_DETAILS}
          policies={mergedPolicies}
          fetchingPriceQuote={priceQuoteInProgress}
          className={clsx({
            "less-padding": isTreesModalExperiment || isTripPurposeXpEnabled,
          })}
        />
        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}

        {isTripPurposeXpEnabled && (
          <TripPurposePanel setTripPurpose={setTripPurpose} isMobile />
        )}

        <PremierCollectionBookMobileButton
          hasContactInfo={!!contactInfo}
          openContactInfo={() => {
            setContactInfoStep(ContactInfoStep.ContactInfoForm);
          }}
          isBookingValid={isBookingValid}
          onConfirmAndBook={() => onBookPremierCollection()}
          // TODO: simplify/trim PremierCollectionBookMobileButton logic
          showPaymentStep={true}
          onApplyRewards={() => {}}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (
            checkoutStep ===
            MobilePremierCollectionBookWorkflowStep.RewardsAndPayment
          ) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={<PriceBreakdownDropdown />}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={priceQuoteInProgress}
        message={PRICE_QUOTE_MESSAGE}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen={true}
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("premier-collection-payment-card-popup", "b2b")}
      fullScreen={true}
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="premier-collection-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className="premier-collection-book-review-trip-button"
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {REVIEW_MY_TRIP_TEXT}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
