import React, { useRef, useEffect } from "react";
import { Box } from "@material-ui/core";
import {
  HotelShopRoomTypePickerRedesign,
  HotelShopRoomTypePickerEnum,
  HotelShopRoomTypePickerVariant,
} from "halifax";
import { MobileRoomPickerConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { MobileShopHeader } from "../MobileShopHeader";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { SELECT_PC_ROOM } from "redmond";
import "./styles.scss";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface MobileRoomPickerProps
  extends MobileRoomPickerConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: () => void;
  isGlobalMobileNavExperiment?: boolean;
  isVRForPremiumCardHoldersEnabled: boolean;
  nonRefundablePolicyOverrideText?: string;
  isFintechHotelUpdatedUxEnabled?: boolean;
}

export const MobileRoomPicker = (props: MobileRoomPickerProps) => {
  const {
    checkinDate,
    checkoutDate,
    history,
    fetchPremierCollectionShop,
    chosenProduct,
    selectRoomType,
    chosenRoomInfoIndex,
    roomInfoProductsType,
    handleReadyToRedirect,
    isGlobalMobileNavExperiment,
    isVRForPremiumCardHoldersEnabled,
    isFintechHotelUpdatedUxEnabled = false,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);
  const expState = useExperiments();

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  useEffect(() => {
    if (checkinDate && checkoutDate) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else {
        fetchPremierCollectionShop(history, {
          forceCallHotelAvailability: true,
          fetchLodgingToken: cacheHotelTokenEnabled,
        });
      }
    }
  }, [checkinDate, checkoutDate]);

  useEffect(() => {
    !chosenProduct && selectRoomType(0, 0);
    setTimeout(() => {
      const id = "chosen-room";
      const yOffset = -122.5;
      const element = document.getElementById(id);
      const y =
        (element?.getBoundingClientRect().top ?? 0) +
        window.pageYOffset +
        yOffset;
      if (chosenRoomInfoIndex || chosenRoomInfoIndex === 0) {
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 50);
  }, []);
  return (
    <Box>
      <MobileShopHeader
        isVRForPremiumCardHoldersEnabled={isVRForPremiumCardHoldersEnabled}
      />
      {/* {isTravelWalletOfferExperiment &&
        selectedLodging?.bestOfferThisLodging && (
          <TravelWalletDetailsBanner
            offer={selectedLodging?.bestOfferThisLodging}
            className="mobile-premierCollection-shop-offer"
            screen={SelectedTravelOfferScreen.HOTEL_DETAILS}
          />
        )} */}
      <HotelShopRoomTypePickerRedesign
        {...props}
        roomInfoProductsType={
          roomInfoProductsType ?? {
            roomInfoProducts: props.roomInfoProducts,
            variant: HotelShopRoomTypePickerEnum.Default,
          }
        }
        isMobile={true}
        className="b2b"
        onClickContinue={() => {
          trackEvent({
            eventName: SELECT_PC_ROOM,
            properties: {
              room_type:
                props.chosenRoomInfo && props.chosenRoomInfo.roomInfo.name,
            },
          });
          if (handleReadyToRedirect) {
            handleReadyToRedirect();
          }
        }}
        isGlobalMobileNavExperiment={isGlobalMobileNavExperiment}
        variant={
          isFintechHotelUpdatedUxEnabled ? "refundable-room-ux" : "default"
        }
      />
    </Box>
  );
};
