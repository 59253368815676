import * as actionTypes from "./constants";
import * as H from "history";
import { LodgingData } from "redmond";
import {
  PremierCollectionShopDetailsCallState,
  PremierCollectionShopDetailsCallError,
} from "../reducer";

export interface IFetchPremierCollectionShopDetails {
  type: actionTypes.FETCH_PREMIER_COLLECTION_SHOP_DETAILS;
  history: H.History;
  options?: {};
}

export const fetchPremierCollectionShopDetails = (
  history: H.History,
  options?: {}
): IFetchPremierCollectionShopDetails => ({
  type: actionTypes.FETCH_PREMIER_COLLECTION_SHOP_DETAILS,
  history,
  options,
});

export interface ISetPremierCollectionShopDetailsResults {
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS;
  premierCollectionShopDetailsCallState: PremierCollectionShopDetailsCallState;
  premierCollectionShopDetailsCallError: PremierCollectionShopDetailsCallError | null;
  payload?: ISetPremierCollectionShopResultsArgs;
}

export interface ISetPremierCollectionShopResultsArgs {
  lodgingData: LodgingData;
}

export const setPremierCollectionShopDetailsResults = (args: {
  premierCollectionShopDetailsCallState: PremierCollectionShopDetailsCallState;
  premierCollectionShopDetailsCallError?: PremierCollectionShopDetailsCallError;
  payload?: ISetPremierCollectionShopResultsArgs;
}): ISetPremierCollectionShopDetailsResults => ({
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS,
  ...{ premierCollectionShopDetailsCallError: null, ...args },
});

interface IISetPremierCollectionShopDetailsCallStateFailedPayload {
  premierCollectionShopDetailsCallError: PremierCollectionShopDetailsCallError;
}
export interface ISetPremierCollectionShopDetailsCallStateFailed
  extends IISetPremierCollectionShopDetailsCallStateFailedPayload {
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED;
}

export const setPremierCollectionShopDetailsCallStateFailed = ({
  premierCollectionShopDetailsCallError,
}: IISetPremierCollectionShopDetailsCallStateFailedPayload): ISetPremierCollectionShopDetailsCallStateFailed => ({
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED,
  premierCollectionShopDetailsCallError,
});

export interface IAcknowledgePremierCollectionShopDetailsFailure {
  type: actionTypes.ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE;
}

export const acknowledgePremierCollectionShopDetailsFailure =
  (): IAcknowledgePremierCollectionShopDetailsFailure => ({
    type: actionTypes.ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE,
  });

export interface ISelectRoomType {
  type: actionTypes.SELECT_ROOM_TYPE;
  roomInfoIndex: number | null;
}

export const selectRoomType = (
  roomInfoIndex: number | null
): ISelectRoomType => {
  return {
    type: actionTypes.SELECT_ROOM_TYPE,
    roomInfoIndex,
  };
};

export interface ISelectLodging {
  type: actionTypes.SELECT_LODGING;
  lodgingData: LodgingData;
}

export const selectLodging = (lodging: LodgingData): ISelectLodging => ({
  type: actionTypes.SELECT_LODGING,
  lodgingData: lodging,
});

export interface IShopCallFromShopDetails {
  type: actionTypes.SHOP_CALL_FROM_SHOP_DETAILS;
  shopCallFromShopDetails: boolean;
  shopCallFailureUrl: string | null;
}

export const shopCallFromShopDetails = (
  shopCallFromShopDetails: boolean,
  shopCallFailureUrl: string | null
): IShopCallFromShopDetails => ({
  type: actionTypes.SHOP_CALL_FROM_SHOP_DETAILS,
  shopCallFromShopDetails: shopCallFromShopDetails,
  shopCallFailureUrl: shopCallFailureUrl,
});

export type PremierCollectionShopDetailsActions =
  | IFetchPremierCollectionShopDetails
  | ISetPremierCollectionShopDetailsResults
  | ISetPremierCollectionShopDetailsCallStateFailed
  | IAcknowledgePremierCollectionShopDetailsFailure
  | ISelectRoomType
  | ISelectLodging
  | IShopCallFromShopDetails;
