import React, { useContext, useEffect } from "react";
import dayjs from "dayjs";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  ButtonWrap,
  HotelAddOn,
  HotelAddOnEnum,
  HotelConfirmation,
  Icon,
  IconName,
  VacationRentalConfirmation,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import "./styles.scss";
import queryStringParser from "query-string";
import {
  CorpSessionInfo,
  ListingCollectionEnum,
  LodgingCollectionEnum,
  ModalNames,
} from "redmond";
import { updateUserSeenModal } from "b2b-base/src/api/v1/user/updateUserSeenModal";
import { useExperimentsById } from "@capone/experiments";
import { ClientContext } from "../../../../App";
import {
  HOTEL_CONFIRMATION_TITLE,
  PORTAL_TITLE,
} from "../../../../lang/textConstants";
import * as textConstants from "./textConstants";
import {
  PATH_BOOK_CONFIRMATION,
  PATH_HOME,
  PATH_TRIPS,
  PATH_VACATION_RENTALS_BOOK,
} from "../../../../utils/paths";
import { BookingSuccessModalConnectorProps } from "./container";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IBookingSuccessModalProps
  extends BookingSuccessModalConnectorProps,
    RouteComponentProps {
  isTreesModalExperiment: boolean;
}

export const BookingSuccessModal = (props: IBookingSuccessModalProps) => {
  const {
    history,
    reservation,
    selectedLodging,
    resetBookState,
    earnString,
    confirmationEmail: email = "",
    isTreesModalExperiment,
    vrReservation,
    selectedHome,
    userPassengers,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    document.title = HOTEL_CONFIRMATION_TITLE;

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  // TODO: since 'reservation' is not preserved on refresh, it would cause undefined error;
  // discuss with PM regarding what the expected behaviour is (e.g.: handle it properly with error modals, or preserve this state).

  if (
    (!reservation && history.location.pathname === PATH_BOOK_CONFIRMATION) ||
    (!vrReservation && history.location.pathname === PATH_VACATION_RENTALS_BOOK)
  ) {
    history.push(PATH_HOME);
    return null;
  }

  const { sessionInfo } = useContext(ClientContext);

  const isNfuProductAwarenessEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "available";

  const isNfuProductAwarenessDebugEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "debug";

  const hasSeenNfuInfoModal =
    (sessionInfo as CorpSessionInfo).corporateInfo?.hasSeenModalMap?.[
      ModalNames.NFU_IN_PRODUCT_AWARENESS_CONFIRMATION
    ] ?? false;

  const shouldShowNfuProductAwareness =
    (!hasSeenNfuInfoModal && isNfuProductAwarenessEnabled) ||
    isNfuProductAwarenessDebugEnabled;

  const checkIn = reservation
    ? dayjs(reservation.checkInDate)
    : dayjs(vrReservation?.stayDates.from);
  const checkOut = reservation
    ? dayjs(reservation.checkOutDate)
    : dayjs(vrReservation?.stayDates.until);

  const getAddOns = () => {
    const addOns: HotelAddOn[] = [];

    const hasCfarIncluded = !!reservation?.bookedAncillaryIds?.cfarContractId;

    if (hasCfarIncluded) {
      addOns.push({
        title: textConstants.CFAR_TITLE,
        component: (
          <Box className="cfar-subtitle-section">
            <Typography className="cfar-subtitle" variant="inherit">
              {textConstants.CFAR_DESCRIPTION_ONE}
              <ButtonWrap
                className="my-trips-link"
                aria-label={
                  textConstants.CFAR_DESCRIPTION_MY_TRIPS_LINK_ARIA_LABEL
                }
                onClick={() =>
                  history.push({
                    pathname: PATH_TRIPS,
                    search: queryStringParser.stringify({
                      tripId: reservation.reservationId,
                    }),
                  })
                }
              >
                {textConstants.CFAR_DESCRIPTION_MY_TRIPS_LINK}
              </ButtonWrap>
              {textConstants.CFAR_DESCRIPTION_TWO}
            </Typography>
          </Box>
        ),
        type: HotelAddOnEnum.CFAR,
      });
    }
    return addOns;
  };

  const treesModalText = {
    treesModalHeader: textConstants.TREES_MODAL_HEADER,
    treesModalTitle: textConstants.TREES_MODAL_TITLE,
    treesModalSubtitle: textConstants.TREES_MODAL_SUBTITLE,
    treesModalImgLocation: textConstants.TREES_MODAL_IMG_LOCATION,
    treesModalLinkCopy: textConstants.TREES_MODAL_CTA_TEXT,
    treesModalBoldedLinkCopy: textConstants.TREES_BOLDED_MODAL_CTA_TEXT,
  };

  useEffect(() => {
    const feedbackDiv =
      window.__mclean_env__.ENV === "production"
        ? document.getElementById("prod-capital-one-travel-web-embed")
        : document.getElementById("test-capital-one-travel-web-embed");
    if (!document.getElementById("embeddedIframe14203")) {
      feedbackDiv?.classList.add("no-background");
    } else {
      if (
        feedbackDiv?.classList.length &&
        feedbackDiv?.classList.contains("no-background")
      ) {
      }
      feedbackDiv?.classList.remove("no-background");
    }
  }, [document.getElementById("embeddedIframe14203")]);

  return (
    <Box
      className={clsx("premium-collection-booking-success-container", {
        mobile: matchesMobile,
        "premier-collection":
          selectedLodging?.lodgingCollection ===
            LodgingCollectionEnum.Premier ||
          selectedHome?.listing.listingCollection ===
            ListingCollectionEnum.Premier,
        "lifestyle-collection":
          selectedLodging?.lodgingCollection ===
            LodgingCollectionEnum.Lifestyle ||
          selectedHome?.listing.listingCollection ===
            ListingCollectionEnum.Lifestyle,
      })}
    >
      {reservation ? (
        <HotelConfirmation
          bannerCopy={
            selectedLodging?.lodgingCollection ===
            LodgingCollectionEnum.Lifestyle
              ? textConstants.LC_BANNER_COPY
              : textConstants.BANNER_COPY
          }
          title={textConstants.TITLE(email)}
          subtitle={textConstants.SUBTITLE(reservation.reservationId)}
          nextHeader={textConstants.WHATS_NEXT_HEADER}
          infoCardTitles={{
            flightsTitle: textConstants.FLIGHTS_TITLE,
            flightsDescription: textConstants.FLIGHTS_DESCRIPTION,
            carsTitle: textConstants.CARS_TITLE,
            carsDescription: textConstants.CARS_DESCRIPTION,
            addOnsTitle: textConstants.ADD_ONS_TITLE,
          }}
          lodging={{
            lodging: reservation.lodgingData,
            description: "",
            isPreferred: false,
            isFreeCancel: false,
            isLuxuryCollection:
              selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Premier || false,
            lodgingCollection: selectedLodging?.lodgingCollection,
          }}
          checkIn={checkIn.toDate()}
          checkOut={checkOut.toDate()}
          onFlightsClick={() => {
            history.push("/flights");
            resetBookState();
          }}
          onCarsClick={() => {
            history.push("/cars");
            resetBookState();
          }}
          isMobile={matchesMobile}
          earnString={earnString}
          addOns={getAddOns()}
          displayTrees={isTreesModalExperiment}
          treesModalText={treesModalText}
          icon={<Icon name={IconName.ConfirmationCheckMarkFilled} />}
          isProdEnv={window.__mclean_env__.ENV === "production"}
          shouldShowNfuProductAwareness={shouldShowNfuProductAwareness}
          travelerName={`${userPassengers[0]?.givenName} ${userPassengers[0]?.surname}`}
          handleSeenNfuInfoModal={updateUserSeenModal}
          trackEvent={trackEvent}
        />
      ) : vrReservation ? (
        <VacationRentalConfirmation
          bannerCopy={
            selectedHome?.listing.listingCollection ===
            ListingCollectionEnum.Lifestyle
              ? textConstants.LC_VR_COPY
              : textConstants.PC_VR_COPY
          }
          selectedHome={vrReservation.listing}
          listingCollection={selectedHome?.listing.listingCollection}
          title={textConstants.TITLE(email)}
          subtitle={textConstants.SUBTITLE(vrReservation.id.value)}
          nextHeader={textConstants.WHATS_NEXT_HEADER}
          infoCardTitles={{
            flightsTitle: textConstants.FLIGHTS_TITLE,
            flightsDescription: textConstants.FLIGHTS_DESCRIPTION,
            carsTitle: textConstants.CARS_TITLE,
            carsDescription: textConstants.CARS_DESCRIPTION,
          }}
          checkIn={checkIn.toDate()}
          checkOut={checkOut.toDate()}
          onFlightsClick={() => {
            history.push("/flights");
            resetBookState();
          }}
          onCarsClick={() => {
            history.push("/cars");
            resetBookState();
          }}
          isMobile={matchesMobile}
          earnString={earnString}
          displayTrees={isTreesModalExperiment}
          treesModalText={treesModalText}
          isProdEnv={window.__mclean_env__.ENV === "production"}
          cancellationPolicy={vrReservation.cancellationPolicy}
        />
      ) : undefined}
    </Box>
  );
};
