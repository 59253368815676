import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getSelectedAccount,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { getPetsCount, getStayType } from "../../../search/reducer";
import { actions } from "../../actions";
import {
  fetchInitialPremierCollectionAvailability,
  fetchInitialVacationRentalsAvailability,
  setMapSearchQuery,
} from "../../actions/actions";
import {
  getFilteredPremierCollectionAvailabilityLodgings,
  getHotelAvailabilityCentroids,
  getPremierCollectionAvailabilitypropertyIdHovered,
  getPremierCollectionAvailabilitypropertyIdInFocus,
  getPremierCollectionAvailabilitySearchLocation,
  getPremierCollectionAvailabilitySearchLocationResult,
  getPremierCollectionQueryParams,
  getSearchedNightCount,
  getVacationRentalAvailabilityListings,
  getViewedPremierCollectionListProperties,
  getViewedVacationRentalListProperties,
} from "../../reducer/selectors";
import { AvailabilityMap } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const visibleLodgings =
    getFilteredPremierCollectionAvailabilityLodgings(state);
  const searchLocation = getPremierCollectionAvailabilitySearchLocation(state);
  const propertyIdInFocus =
    getPremierCollectionAvailabilitypropertyIdInFocus(state);
  const propertyIdHovered =
    getPremierCollectionAvailabilitypropertyIdHovered(state);
  const nightCount = getSearchedNightCount(state);
  const searchedLocation =
    getPremierCollectionAvailabilitySearchLocationResult(state);
  const accountReferenceId =
    getSelectedAccountReferenceIdIfRedemptionEnabled(state);
  const selectedAccount = getSelectedAccount(state);
  const hotelQueryParams = getPremierCollectionQueryParams(state);
  const searchCentroids = getHotelAvailabilityCentroids(state);
  return {
    lodgings: visibleLodgings,
    searchLocation,
    propertyIdInFocus,
    propertyIdHovered,
    nightCount,
    searchedLocation,
    accountReferenceId,
    selectedAccount,
    hotelQueryParams,
    mapSearchLocation: state.premierCollectionAvailability.searchLocationResult,
    viewedPremierCollectionListProperties:
      getViewedPremierCollectionListProperties(state),
    stayType: getStayType(state),
    vacationRentalsListings: getVacationRentalAvailabilityListings(state),
    petsCount: getPetsCount(state),
    viewedVacationRentalListProperties:
      getViewedVacationRentalListProperties(state),
    searchCentroids,
  };
};

const mapDispatchToProps = {
  setPropertyIdInFocus: actions.setPropertyIdInFocus,
  setSelectedLodgingIndex: actions.setSelectedLodgingIndex,
  fetchInitialPremierCollectionAvailability,
  fetchInitialVacationRentalsAvailability,
  setMapSearchQuery,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityMapConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityMap = connector(withRouter(AvailabilityMap));
