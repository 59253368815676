import React from "react";
import { MobileHotelShopRoomTypePickerPanel } from "halifax";
import { MobilePremierCollectionShopRoomTypePickerPanelConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { PremierCollectionShopStep } from "../../reducer";
import { config } from "../../../../api/config";
import { CorpRoomInfoProducts } from "redmond";
import { getRoomPolicyComplianceType } from "@capone/common/src/utils/hotelShopPolicyCompliance";

export interface MobilePremierCollectionShopRoomTypePickerPanelProps
  extends MobilePremierCollectionShopRoomTypePickerPanelConnectorProps,
    RouteComponentProps {
  onContinueClicked?: () => void;
}

export const MobilePremierCollectionShopRoomTypePickerPanel = (
  props: MobilePremierCollectionShopRoomTypePickerPanelProps
) => {
  const {
    setPremierCollectionShopProgress,
    rewardsKey,
    onContinueClicked,
    selectedLodging,
  } = props;

  const includeTaxesAndFeesInTotalPrice =
    selectedLodging?.price?.displayTaxesAndFeesIncluded;

  React.useEffect(() => {
    const footer = document.querySelector(
      ".mclean-generic-footer"
    ) as HTMLElement;
    const fixedComponent = document.querySelector(
      ".mobile-hotel-shop-price-panel"
    ) as HTMLElement;
    const travelWalletDrawerButton = document.querySelector(
      ".travel-wallet-button"
    ) as HTMLElement;
    if (fixedComponent && footer) {
      footer.style.paddingBottom = `${fixedComponent.clientHeight}px`;
    }
    if (fixedComponent && travelWalletDrawerButton) {
      travelWalletDrawerButton.style.bottom = `${
        fixedComponent.clientHeight + 20
      }px`;
    }
    return () => {
      if (footer) {
        footer.style.paddingBottom = "85px";
      }
      if (travelWalletDrawerButton) {
        travelWalletDrawerButton.style.bottom = "100px";
      }
    };
  }, [rewardsKey]);
  return (
    <MobileHotelShopRoomTypePickerPanel
      {...props}
      onClickContinue={() => {
        setPremierCollectionShopProgress(PremierCollectionShopStep.ChooseRoom);
        if (onContinueClicked) {
          onContinueClicked();
        }
      }}
      className="b2b"
      tenant={config.TENANT}
      policyComplianceType={getRoomPolicyComplianceType(
        props.roomInfoProducts as CorpRoomInfoProducts[]
      )}
      showPrice={!includeTaxesAndFeesInTotalPrice}
    ></MobileHotelShopRoomTypePickerPanel>
  );
};
