import React, { useState, useMemo, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  ActionLink,
  MobilePopoverCard,
  MobileFloatingButton,
  Icon,
  IconName,
  CloseButtonIcon,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileShopHeaderConnectorProps } from "./container";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../search/components/PremierCollectionSearchControl/components/MonthAndDatePicker";
import { goToAvailability } from "../../utils/queryStringHelpers";
import "./styles.scss";
import { PremierCollectionShopStep } from "../../reducer";
import * as textConstants from "./textConstants";
import { goToVRAvailability } from "../../../vacation-rental-shop/utils/queryStringHelpers";
import { StayTypesEnum } from "redmond";

import {
  getExperimentVariant,
  CACHE_HOTEL_TOKEN,
  AVAILABLE,
  useExperiments,
} from "../../../../context/experiments";

export interface IMobileShopHeaderProps
  extends RouteComponentProps,
    MobileShopHeaderConnectorProps {
  onClickBackButton?: () => void;
  title?: string;
  // note: the value for `variant` cannot change, because it can affect hooks at runtime
  variant?: "custom" | "shop";
  isVR?: boolean;
  isVRForPremiumCardHoldersEnabled: boolean;
  isVRCalendarEnabled?: boolean;
  unavailableDates?: Date[];
}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const {
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    selectedLodging,
    fetchPremierCollectionShop,
    setFromDate,
    setUntilDate,
    history,
    premierCollectionShopStep,
    setPremierCollectionShopProgress,
    onClickBackButton,
    title,
    variant = "shop",
    isVR = false,
    selectedListing,
    petsCount,
    stayType,
    fetchVacationRentalShop,
    isVRForPremiumCardHoldersEnabled,
    openDatesModal,
    setOpenDatesModal,
    isVRCalendarEnabled,
    unavailableDates,
  } = props;
  const [hasChangedDates, setHasChangedDates] = useState(false);
  const [from, setFrom] = useState<Date | null>(fromDate);
  const [until, setUntil] = useState<Date | null>(untilDate);
  const expState = useExperiments();

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const onClose = () => setOpenDatesModal(false);

  const returnToAvailability = () => {
    isVR
      ? goToVRAvailability({
          history,
          listing: selectedListing,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
          petsCount,
        })
      : goToAvailability({
          history,
          lodging: selectedLodging,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
        });
  };

  if (variant === "shop") {
    useEffect(() => {
      setFrom(fromDate);
    }, [fromDate]);

    useEffect(() => {
      setUntil(untilDate);
    }, [untilDate]);

    useEffect(() => {
      const differentDates = from !== fromDate || until !== untilDate;
      const setBothDates = fromDate && untilDate;

      if (differentDates && setBothDates) {
        setHasChangedDates(true);
      } else {
        setHasChangedDates(false);
      }
    }, [fromDate, untilDate, from, until]);
  }

  const defaultOnClickBackButton = useMemo(() => {
    if (premierCollectionShopStep === PremierCollectionShopStep.ChooseRoom) {
      return () =>
        setPremierCollectionShopProgress(PremierCollectionShopStep.HotelInfo);
    }

    return returnToAvailability;
  }, [premierCollectionShopStep]);

  const MiddleSection = () => {
    switch (variant) {
      case "custom":
        return (
          <Box className={clsx("title-section", "header-middle-section")}>
            <Typography variant="body1" className="title">
              {title}
            </Typography>
          </Box>
        );
      case "shop":
      default:
        return (
          <>
            <Box
              className={clsx(
                "locations-and-dates-section",
                "header-middle-section"
              )}
            >
              {premierCollectionShopStep ===
              PremierCollectionShopStep.HotelInfo ? (
                <>
                  <Typography variant="body1" className="locations-section">
                    {stayType === StayTypesEnum.VacationRentals
                      ? selectedListing?.listing.content.name
                      : selectedLodging?.lodging.name}
                  </Typography>
                  <Box className="dates-and-occupancy-count-section">
                    <Typography variant="body2" className="dates-section">
                      {fromDate &&
                        untilDate &&
                        `${dayjs(fromDate).format("MMM DD")} - ${dayjs(
                          untilDate
                        ).format("MMM DD")}, `}
                      {adultsCount + childrenCount > 1
                        ? `${adultsCount + childrenCount} Travelers`
                        : `${adultsCount + childrenCount} Traveler`}
                    </Typography>
                  </Box>
                </>
              ) : (
                textConstants.CHOOSE_ROOM
              )}
            </Box>
            {premierCollectionShopStep ===
              PremierCollectionShopStep.HotelInfo && (
              <Box className="edit-location-and-date-button-section">
                <ActionLink
                  className="edit-location-and-date-button"
                  onClick={() => {
                    setOpenDatesModal(true);
                  }}
                  content={
                    <Icon name={IconName.B2BEditPencil} className="edit-icon" />
                  }
                />
              </Box>
            )}
          </>
        );
    }
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={onClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="pc-shop-progress-header-root">
        <Box className="pc-shop-progress-header-container">
          <Box className="go-back-button-section">
            <ActionLink
              className={clsx("go-back-button")}
              onClick={
                variant === "shop"
                  ? defaultOnClickBackButton
                  : onClickBackButton
              }
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          </Box>
          <MiddleSection />
        </Box>
      </Box>
      <MobilePopoverCard
        open={openDatesModal}
        onClose={onClose}
        className="mobile-pc-shop-header-root"
        contentClassName="mobile-shop-header-content-wrapper"
        headerElement={<MobileSearchPopoverHeader />}
      >
        <Box className="mobile-shop-header-content-container">
          <Box className="pc-mobile-calendar-picker-root">
            <MonthAndDatePicker
              viewType={MonthAndDatePickerType.Column}
              from={from}
              until={until}
              setFromDate={setFrom}
              setUntilDate={setUntil}
              className="b2b"
              header={
                <Box className="premier-collection-mobile-calendar-header">
                  <DatePickerHeader
                    label={textConstants.CHECK_IN}
                    value={from ? dayjs(from).format("ddd D, MMM") : undefined}
                  />
                  <DatePickerHeader
                    label={textConstants.CHECK_OUT}
                    value={
                      until ? dayjs(until).format("ddd D, MMM") : undefined
                    }
                  />
                </Box>
              }
              unavailableDates={unavailableDates}
            />
            {hasChangedDates && (
              <MobileFloatingButton
                className="mobile-calendar-picker-search-button"
                disabled={!(!!from && !!until)}
                onClick={() => {
                  setFromDate(from);
                  setUntilDate(until);
                  stayType === StayTypesEnum.VacationRentals
                    ? fetchVacationRentalShop(
                        history,
                        {
                          forceCallVRAvailability: true,
                          fetchListingCalendar: isVRCalendarEnabled,
                        },
                        isVRForPremiumCardHoldersEnabled
                      )
                    : fetchPremierCollectionShop(history, {
                        forceCallHotelAvailability: true,
                        fetchLodgingToken: cacheHotelTokenEnabled,
                      });
                  setOpenDatesModal(false);
                }}
                wrapperClassName="b2b"
              >
                {textConstants.SEARCH_DATES}
              </MobileFloatingButton>
            )}
          </Box>
        </Box>
      </MobilePopoverCard>
    </>
  );
};
