import { delay, putResolve, select } from "redux-saga/effects";
import pollFinalized from "../../../../api/v0/book/book-flow/pollFinalized";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyPremierCollectionProperties,
  getDatelessSearchTrackingProperty,
  getSession,
  getTripPurpose,
} from "../../reducer";
import {
  COMPLETE_BUY_LC,
  COMPLETE_BUY_PC,
  DatelessSearchProperties,
  getNthNightTrackingProperty,
  ITrackingProperties,
  Lodging,
  LodgingCollectionEnum,
  Reservation,
} from "redmond";
import {
  IPollConfirmationDetails,
  setConfirmationDetails,
  setPollConfirmationDetailsCallStateFailure,
  setPollConfirmationDetailsCallStateSuccess,
} from "../../actions/actions";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  ErrorCode,
  FulfillFailure,
  FulfillResponseEnum,
  FulfillResponseV0,
  FulfillSuccessV0,
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { getPremierCollectionShopSelectedAvailability } from "../../../shop/reducer";

const toErrorString = (error: PurchaseError): string => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      return (error as ErrorCode).code;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      return JSON.stringify(paymentError.value.value);
    case PurchaseErrorEnum.ProductError:
      const productError = error as ProductError;
      return JSON.stringify(productError.value.value);
    default:
      return PurchaseErrorEnum[error.Error];
  }
};

export function* pollConfirmationDetailsSaga({
  agentFee,
  isRecommended,
}: IPollConfirmationDetails) {
  const state: IStoreState = yield select();
  try {
    const sessionToken = getSession(state);
    const completeBuyPremierCollectionProperties =
      getCompleteBuyPremierCollectionProperties(state);
    const selectedLodging = getPremierCollectionShopSelectedAvailability(state);
    const tripPurpose = getTripPurpose(state);

    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }
    const delayTimes = [1000];
    let pollFailed = false;
    let index = 0;

    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const finalizedCheckedResponse: FulfillResponseV0 = yield pollFinalized(
        sessionToken
      ).catch((e) => {
        // [CMKT-1150] do not error out on network errors, instead retry
        if (e.response.status !== 200) {
          return Promise.resolve({
            data: { result: FulfillResponseEnum.Pending },
            status: 200,
            statusText: "Gateway timeout",
            headers: [],
            config: {},
          });
        }
        return Promise.reject(e);
      });

      switch (finalizedCheckedResponse.FulfillResponse) {
        case FulfillResponseEnum.Failure:
          pollFailed = true;
          const failedResponse = finalizedCheckedResponse as FulfillFailure;
          trackEvent({
            eventName:
              selectedLodging?.lodgingCollection ===
              LodgingCollectionEnum.Lifestyle
                ? COMPLETE_BUY_LC
                : COMPLETE_BUY_PC,
            properties: {
              ...completeBuyPremierCollectionProperties.properties,
              success: false,
              agent_booking_fee_amount_usd: agentFee,
              failure_reason:
                failedResponse.errors.length > 0
                  ? failedResponse.errors
                      .map((error) => toErrorString(error))
                      .join(" - ")
                  : "Poll Confirmation Details response returned an error and the given error code is not handleable.",
              is_recommended: isRecommended,
              purpose_of_travel: tripPurpose,
            },
            encryptedProperties: [
              ...completeBuyPremierCollectionProperties.encryptedProperties,
            ],
          });
          if (failedResponse.errors.length > 0) {
            yield putResolve(
              setPollConfirmationDetailsCallStateFailure(failedResponse.errors)
            );
            return;
          } else {
            yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
            throw new Error(
              "Poll Confirmation Details response returned an error and the given error code is not handleable."
            );
          }

        case FulfillResponseEnum.Pending:
          break;
        case FulfillResponseEnum.Success:
          const finalizedResponse =
            finalizedCheckedResponse as FulfillSuccessV0;
          switch (finalizedResponse.fulfillment.type) {
            case Product.Hotel:
              const reservation = finalizedResponse.fulfillment
                .value as Reservation;
              yield putResolve(setConfirmationDetails(reservation));
              yield putResolve(setPollConfirmationDetailsCallStateSuccess());
              trackEvent({
                eventName:
                  reservation.lodgingCollection ===
                  LodgingCollectionEnum.Lifestyle
                    ? COMPLETE_BUY_LC
                    : COMPLETE_BUY_PC,
                properties: {
                  ...completeBuyPremierCollectionProperties.properties,
                  success: true,
                  agent_booking_fee_amount_usd: agentFee,
                  reservation_id: reservation.reservationId,
                  is_recommended: isRecommended,
                },
                encryptedProperties: [
                  ...completeBuyPremierCollectionProperties.encryptedProperties,
                ],
              });
              return;
            default:
              pollFailed = true;
              trackEvent({
                eventName:
                  selectedLodging?.lodgingCollection ===
                  LodgingCollectionEnum.Lifestyle
                    ? COMPLETE_BUY_LC
                    : COMPLETE_BUY_PC,
                properties: {
                  ...completeBuyPremierCollectionProperties.properties,
                  success: false,
                  agent_booking_fee_amount_usd: agentFee,
                  failure_reason:
                    "Poll Confirmation Details Success Response Failed",
                  is_recommended: isRecommended,
                  purpose_of_travel: tripPurpose,
                },
                encryptedProperties: [
                  ...completeBuyPremierCollectionProperties.encryptedProperties,
                ],
              });
              yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
              throw new Error("Poll Finalized Failed");
          }
      }

      // if we want to give up on polling we should instead direct the user to my trips
      if (index >= delayTimes.length) {
        window.location.pathname = "/trips";
      }

      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    const selectedLodging: Lodging | null = yield select(
      getPremierCollectionShopSelectedAvailability
    );
    const tripPurpose: string | null = yield select(getTripPurpose);
    // const completeBuyPremierCollectionProperties: ITrackingProperties<CompleteBuyHotelProperties> =
    //   yield select(getCompleteBuyPremierCollectionProperties);
    const datelessSearchTrackingProperty: ITrackingProperties<DatelessSearchProperties> =
      yield select(getDatelessSearchTrackingProperty);
    trackEvent({
      eventName:
        selectedLodging?.lodgingCollection === LodgingCollectionEnum.Lifestyle
          ? COMPLETE_BUY_LC
          : COMPLETE_BUY_PC,
      properties: {
        // ...completeBuyPremierCollectionProperties.properties,
        agent_booking_fee_amount_usd: agentFee,
        failure_reason: "Poll Confirmation Details Network Call Failed",
        is_recommended: isRecommended,
        purpose_of_travel: tripPurpose,
        nth_night_promo: getNthNightTrackingProperty(selectedLodging),
        ...datelessSearchTrackingProperty.properties,
      },
      encryptedProperties: [
        // ...completeBuyPremierCollectionProperties.encryptedProperties,
        ...datelessSearchTrackingProperty.encryptedProperties,
      ],
    });
    yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
  }
}
